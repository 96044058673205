import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private http: HttpClient) { }


  uploadResume(firstName,lastName,emailId,phoneNumber,pdf:File){
    const postData = new FormData();
    postData.append("firstName", firstName);
    postData.append("lastName", lastName);
    postData.append("emailId", emailId);
    postData.append("phoneNumber", phoneNumber);
    postData.append("pdf", pdf);
   
    return this.http.post<{ 
      error: boolean, 
      message: string}>(`${environment.baseUrl}/resume/upload-resume`,postData)
  }

  freeEnquery(freeEnquiryData){
    // const postData = new FormData();
    // postData.append("firstName", firstName);
    // postData.append("lastName", lastName);
    // postData.append("emailId", emailId);
    // postData.append("phoneNumber", phoneNumber);
    // postData.append("pdf", pdf);
   
    return this.http.post<{ 
      error: boolean, 
      message: string}>(`${environment.baseUrl}/enquiry/free-enquiry`,freeEnquiryData)
  }

  
  enqueryAcademic(postDataAcademin){
    // console.log(firstName,lastName,emailId,phoneNumber,message);
    
    // const postDataAcademin = new FormData();
    // postDataAcademin.append("firstName", firstName);
    // postDataAcademin.append("lastName", lastName);
    // postDataAcademin.append("emailId", emailId);
    // postDataAcademin.append("phoneNumber", phoneNumber);
    // postDataAcademin.append("message", message);
   console.log("service", postDataAcademin);
   
    return this.http.post<{ 
      error: boolean, 
      message: string}>(`${environment.baseUrl}/enquiry/enquiry-academic`,postDataAcademin)
  }
}
