import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @ViewChild('closeSidebar') closeSidebar:ElementRef
  
  constructor(
    private route:Router
  ) { }

  ngOnInit(): void {
  }
  onHome(){
    this.route.navigate(['home']);
    this.closeSidebar.nativeElement.click()
  }
  
  onSolutionProduct(){
    console.log("hello");
    this.route.navigate(['/solutions/products']);
    this.closeSidebar.nativeElement.click()
   
  }
  onSolutionServices(){
    this.route.navigate(['solutions/services']);
    this.closeSidebar.nativeElement.click()
  }
  onSolutionIT(){
    this.route.navigate(['solutions/it-solutions']);
    this.closeSidebar.nativeElement.click()
  }
  onAcademic(){
    this.route.navigate(['academic']);
    this.closeSidebar.nativeElement.click()
  }
  onRecruitmentProfessional(){
    this.route.navigate(['recruitment/professional-service']);
    this.closeSidebar.nativeElement.click()
  }
  onRecruitmentManaged(){
    this.route.navigate(['recruitment/managed-service']);
    this.closeSidebar.nativeElement.click()
  }
  onRecruitmentCorporate(){
    this.route.navigate(['recruitment/corporate-training']);
    this.closeSidebar.nativeElement.click()
  }
}
