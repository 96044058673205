

<div>
  <section class="features4 cid-rIyFvrE6Cu" style="padding: 0;" id="features4-17">
    <div>
      <div class="mbr-row">
        <div class="mbr-col-sm-12 mbr-col-md-6 mbr-col-lg-3 mbr-flex mbr-jc-c mbr-align-center box1">

          <div class="text__block text1 align-center">
            <div class="align-center">
              <span class="mbr-iconfont-btn mbr-iconfont icon__text fa-language fa">
                <svg width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor">
                  <path
                    d="M782 1078q-1 3-12.5-.5t-31.5-11.5l-20-9q-44-20-87-49-7-5-41-31.5t-38-28.5q-67 103-134 181-81 95-105 110-4 2-19.5 4t-18.5 0q6-4 82-92 21-24 85.5-115t78.5-118q17-30 51-98.5t36-77.5q-8-1-110 33-8 2-27.5 7.5t-34.5 9.5-17 5q-2 2-2 10.5t-1 9.5q-5 10-31 15-23 7-47 0-18-4-28-21-4-6-5-23 6-2 24.5-5t29.5-6q58-16 105-32 100-35 102-35 10-2 43-19.5t44-21.5q9-3 21.5-8t14.5-5.5 6 .5q2 12-1 33 0 2-12.5 27t-26.5 53.5-17 33.5q-25 50-77 131l64 28q12 6 74.5 32t67.5 28q4 1 10.5 25.5t4.5 30.5zm-205-486q3 15-4 28-12 23-50 38-30 12-60 12-26-3-49-26-14-15-18-41l1-3q3 3 19.5 5t26.5 0 58-16q36-12 55-14 17 0 21 17zm698 129l63 227-139-42zm-1108 800l694-232v-1032l-694 233v1031zm1241-317l102 31-181-657-100-31-216 536 102 31 45-110 211 65zm-503-962l573 184v-380zm311 1323l158 13-54 160-40-66q-130 83-276 108-58 12-91 12h-84q-79 0-199.5-39t-183.5-85q-8-7-8-16 0-8 5-13.5t13-5.5q4 0 18 7.5t30.5 16.5 20.5 11q73 37 159.5 61.5t157.5 24.5q95 0 167-14.5t157-50.5q15-7 30.5-15.5t34-19 28.5-16.5zm448-1079v1079l-774-246q-14 6-375 127.5t-368 121.5q-13 0-18-13 0-1-1-3v-1078q3-9 4-10 5-6 20-11 107-36 149-50v-384l558 198q2 0 160.5-55t316-108.5 161.5-53.5q20 0 20 21v418z">
                  </path>
                </svg></span></div>
            <h3 class="mbr-fonts-style mbr-mt-1 mbr-section-title mbr-bold mbr-white display-2">Solutions</h3>
            <p class="mbr-fonts-style card__text display-4"> Product</p>
            <p class="mbr-fonts-style card__text display-4">Services</p>
            <p class="mbr-fonts-style card__text display-4">It-Solutions</p>
            <div class="mbr-section-btn btn_Hover" (click)="onProducts()">
              <a class="btn btn-md btn-primary mbr-mt-2 display-4">
                <span class="mbrib-right mbr-iconfont mbr-iconfont-btn"><svg version="1.1"
                    xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32"
                    fill="currentColor">

                    <path
                      d="M17.724 0.3l14.016 14.008c0.348 0.368 0.348 1.008 0 1.376l-14.016 14.010c-0.94 0.936-2.332-0.494-1.44-1.376l13.328-13.324-13.328-13.318c-0.876-0.876 0.52-2.3 1.44-1.38zM1 16h24c1.304 0 1.37-2 0-2h-24c-1.35 0-1.28 2 0 2z">
                    </path>
                  </svg></span>
                <span class="btn__hover">Read More</span>
              </a></div>
          </div>
        </div>
        <div class="mbr-col-sm-12 mbr-col-md-6 mbr-col-lg-3 mbr-flex mbr-jc-c mbr-align-center box1 box2">

          <div class="text__block text1 align-center">
            <div class="align-center"><span class="mbr-iconfont-btn mbr-iconfont icon__text fa-language fa"><svg
                  width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor">
                  <path
                    d="M782 1078q-1 3-12.5-.5t-31.5-11.5l-20-9q-44-20-87-49-7-5-41-31.5t-38-28.5q-67 103-134 181-81 95-105 110-4 2-19.5 4t-18.5 0q6-4 82-92 21-24 85.5-115t78.5-118q17-30 51-98.5t36-77.5q-8-1-110 33-8 2-27.5 7.5t-34.5 9.5-17 5q-2 2-2 10.5t-1 9.5q-5 10-31 15-23 7-47 0-18-4-28-21-4-6-5-23 6-2 24.5-5t29.5-6q58-16 105-32 100-35 102-35 10-2 43-19.5t44-21.5q9-3 21.5-8t14.5-5.5 6 .5q2 12-1 33 0 2-12.5 27t-26.5 53.5-17 33.5q-25 50-77 131l64 28q12 6 74.5 32t67.5 28q4 1 10.5 25.5t4.5 30.5zm-205-486q3 15-4 28-12 23-50 38-30 12-60 12-26-3-49-26-14-15-18-41l1-3q3 3 19.5 5t26.5 0 58-16q36-12 55-14 17 0 21 17zm698 129l63 227-139-42zm-1108 800l694-232v-1032l-694 233v1031zm1241-317l102 31-181-657-100-31-216 536 102 31 45-110 211 65zm-503-962l573 184v-380zm311 1323l158 13-54 160-40-66q-130 83-276 108-58 12-91 12h-84q-79 0-199.5-39t-183.5-85q-8-7-8-16 0-8 5-13.5t13-5.5q4 0 18 7.5t30.5 16.5 20.5 11q73 37 159.5 61.5t157.5 24.5q95 0 167-14.5t157-50.5q15-7 30.5-15.5t34-19 28.5-16.5zm448-1079v1079l-774-246q-14 6-375 127.5t-368 121.5q-13 0-18-13 0-1-1-3v-1078q3-9 4-10 5-6 20-11 107-36 149-50v-384l558 198q2 0 160.5-55t316-108.5 161.5-53.5q20 0 20 21v418z">
                  </path>
                </svg></span></div>
            <h3 class="mbr-fonts-style mbr-mt-1 mbr-section-title mbr-bold mbr-white display-2">Academic</h3>
            <!-- <p class="mbr-fonts-style card__text display-4"> Product</p>
          <p class="mbr-fonts-style card__text display-4">Services</p>
          <p class="mbr-fonts-style card__text display-4">It-Solutions</p> -->
            <div class="mbr-section-btn btn_Hover" (click)="onAcademic()">
              <a class="btn btn-md btn-primary mbr-mt-2 display-4">
                <span class="mbrib-right mbr-iconfont mbr-iconfont-btn"><svg version="1.1"
                    xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32"
                    fill="currentColor">
                    <path
                      d="M17.724 0.3l14.016 14.008c0.348 0.368 0.348 1.008 0 1.376l-14.016 14.010c-0.94 0.936-2.332-0.494-1.44-1.376l13.328-13.324-13.328-13.318c-0.876-0.876 0.52-2.3 1.44-1.38zM1 16h24c1.304 0 1.37-2 0-2h-24c-1.35 0-1.28 2 0 2z">
                    </path>
                  </svg></span>
                <span class="btn__hover">Read More</span>
              </a>
            </div>
          </div>
        </div>
        <div class="mbr-col-sm-12 mbr-col-md-6 mbr-col-lg-3 mbr-flex mbr-jc-c mbr-align-center box1 box3">
          <div class="text__block text1 align-center">
            <div class="align-center" style="opacity: 1;"><span
                class="mbr-iconfont-btn mbr-iconfont icon__text fa-language fa"><svg width="100%" height="100%"
                  viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                  <path
                    d="M782 1078q-1 3-12.5-.5t-31.5-11.5l-20-9q-44-20-87-49-7-5-41-31.5t-38-28.5q-67 103-134 181-81 95-105 110-4 2-19.5 4t-18.5 0q6-4 82-92 21-24 85.5-115t78.5-118q17-30 51-98.5t36-77.5q-8-1-110 33-8 2-27.5 7.5t-34.5 9.5-17 5q-2 2-2 10.5t-1 9.5q-5 10-31 15-23 7-47 0-18-4-28-21-4-6-5-23 6-2 24.5-5t29.5-6q58-16 105-32 100-35 102-35 10-2 43-19.5t44-21.5q9-3 21.5-8t14.5-5.5 6 .5q2 12-1 33 0 2-12.5 27t-26.5 53.5-17 33.5q-25 50-77 131l64 28q12 6 74.5 32t67.5 28q4 1 10.5 25.5t4.5 30.5zm-205-486q3 15-4 28-12 23-50 38-30 12-60 12-26-3-49-26-14-15-18-41l1-3q3 3 19.5 5t26.5 0 58-16q36-12 55-14 17 0 21 17zm698 129l63 227-139-42zm-1108 800l694-232v-1032l-694 233v1031zm1241-317l102 31-181-657-100-31-216 536 102 31 45-110 211 65zm-503-962l573 184v-380zm311 1323l158 13-54 160-40-66q-130 83-276 108-58 12-91 12h-84q-79 0-199.5-39t-183.5-85q-8-7-8-16 0-8 5-13.5t13-5.5q4 0 18 7.5t30.5 16.5 20.5 11q73 37 159.5 61.5t157.5 24.5q95 0 167-14.5t157-50.5q15-7 30.5-15.5t34-19 28.5-16.5zm448-1079v1079l-774-246q-14 6-375 127.5t-368 121.5q-13 0-18-13 0-1-1-3v-1078q3-9 4-10 5-6 20-11 107-36 149-50v-384l558 198q2 0 160.5-55t316-108.5 161.5-53.5q20 0 20 21v418z">
                  </path>
                </svg></span></div>
            <h3 class="mbr-fonts-style mbr-mt-1 mbr-section-title mbr-bold mbr-white display-2">
              Recruitment
            </h3>
            <p class="mbr-fonts-style card__text mbr-white display-4">Professional service </p>
            <p class="mbr-fonts-style card__text mbr-white display-4">Managed Services </p>
            <p class="mbr-fonts-style card__text mbr-white display-4">Corporate training Factory</p>
            <!-- <p class="mbr-fonts-style card__text mbr-white display-4">Recruitment Consultants</p>
            <p class="mbr-fonts-style card__text mbr-white display-4">Permanent Staffing</p> -->
            <div class="mbr-section-btn btn_Hover">
              <a class="btn btn-md btn-primary mbr-mt-2 display-4" (click)="onRecruitment()">
                <span class="mbrib-right mbr-iconfont mbr-iconfont-btn"><svg version="1.1"
                    xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32"
                    fill="currentColor">

                    <path
                      d="M17.724 0.3l14.016 14.008c0.348 0.368 0.348 1.008 0 1.376l-14.016 14.010c-0.94 0.936-2.332-0.494-1.44-1.376l13.328-13.324-13.328-13.318c-0.876-0.876 0.52-2.3 1.44-1.38zM1 16h24c1.304 0 1.37-2 0-2h-24c-1.35 0-1.28 2 0 2z">
                    </path>
                  </svg></span>
                <span class="btn__hover">Read More</span>
              </a></div>
          </div>
        </div>
        <div class="mbr-col-sm-12 mbr-col-md-6 mbr-col-lg-3 mbr-flex mbr-jc-c mbr-align-center box1 box4">

          <div class="text__block text1 align-center">
            <div class="align-center"><span class="mbr-iconfont-btn mbr-iconfont icon__text fa-language fa"><svg
                  width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor">
                  <path
                    d="M782 1078q-1 3-12.5-.5t-31.5-11.5l-20-9q-44-20-87-49-7-5-41-31.5t-38-28.5q-67 103-134 181-81 95-105 110-4 2-19.5 4t-18.5 0q6-4 82-92 21-24 85.5-115t78.5-118q17-30 51-98.5t36-77.5q-8-1-110 33-8 2-27.5 7.5t-34.5 9.5-17 5q-2 2-2 10.5t-1 9.5q-5 10-31 15-23 7-47 0-18-4-28-21-4-6-5-23 6-2 24.5-5t29.5-6q58-16 105-32 100-35 102-35 10-2 43-19.5t44-21.5q9-3 21.5-8t14.5-5.5 6 .5q2 12-1 33 0 2-12.5 27t-26.5 53.5-17 33.5q-25 50-77 131l64 28q12 6 74.5 32t67.5 28q4 1 10.5 25.5t4.5 30.5zm-205-486q3 15-4 28-12 23-50 38-30 12-60 12-26-3-49-26-14-15-18-41l1-3q3 3 19.5 5t26.5 0 58-16q36-12 55-14 17 0 21 17zm698 129l63 227-139-42zm-1108 800l694-232v-1032l-694 233v1031zm1241-317l102 31-181-657-100-31-216 536 102 31 45-110 211 65zm-503-962l573 184v-380zm311 1323l158 13-54 160-40-66q-130 83-276 108-58 12-91 12h-84q-79 0-199.5-39t-183.5-85q-8-7-8-16 0-8 5-13.5t13-5.5q4 0 18 7.5t30.5 16.5 20.5 11q73 37 159.5 61.5t157.5 24.5q95 0 167-14.5t157-50.5q15-7 30.5-15.5t34-19 28.5-16.5zm448-1079v1079l-774-246q-14 6-375 127.5t-368 121.5q-13 0-18-13 0-1-1-3v-1078q3-9 4-10 5-6 20-11 107-36 149-50v-384l558 198q2 0 160.5-55t316-108.5 161.5-53.5q20 0 20 21v418z">
                  </path>
                </svg></span></div>
            <h3 class="mbr-fonts-style mbr-mt-1 mbr-section-title mbr-bold mbr-white display-2">
              Innovations
            </h3>
            <!-- <p class="mbr-fonts-style card__text display-4"> ManPower</p>
          <p class="mbr-fonts-style card__text display-4">Contract Staffing</p>
          <p class="mbr-fonts-style card__text display-4">HR Consultants</p>
          <p class="mbr-fonts-style card__text display-4">Recruitment Consultants</p>
          <p class="mbr-fonts-style card__text display-4">Permanent Staffing</p> -->
            <div class="mbr-section-btn btn_Hover"><a class="btn btn-md btn-primary mbr-mt-2 display-4" href="#">
                <span class="mbrib-right mbr-iconfont mbr-iconfont-btn"><svg version="1.1"
                    xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32"
                    fill="currentColor">

                    <path
                      d="M17.724 0.3l14.016 14.008c0.348 0.368 0.348 1.008 0 1.376l-14.016 14.010c-0.94 0.936-2.332-0.494-1.44-1.376l13.328-13.324-13.328-13.318c-0.876-0.876 0.52-2.3 1.44-1.38zM1 16h24c1.304 0 1.37-2 0-2h-24c-1.35 0-1.28 2 0 2z">
                    </path>
                  </svg></span>
                <span class="btn__hover">Read More</span>
              </a></div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <!-- -------- About Us Start ------------------ -->


  <section class="about2 cid-rIyFVpTR8t" id="about2-1l">




    <div class="container">
      <div class="mbr-row wrapper mbr-row-reverse">


        <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-6 mbr-flex mbr-align-center ">
          <div class="text__block align-left">
            <div class="title">
              <h3 class="mbr-fonts-style card__title mbr-bold mbr-black display-2">About Us</h3>
              <span class="divider"></span>
            </div>
            <p class="mbr-fonts-style card__text mbr-medium mbr-section-subtitle display-4">We are IT
              services company with a unique difference.</p>



            <div class="content__wrapper mbr-section-subtitle">

              <div class="mbr-flex">
                <span class="fa fa-check mbr-iconfont"><svg width="100%" height="100%" viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                    <path
                      d="M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z">
                    </path>
                  </svg></span>
                <p class="mbr-fonts-style item__service display-4">Chirait Services today provides
                  infrastructure support for a number of customers in India, making Chira Information
                  Technologies one of the fastest growing IT infrastructure solutions providers in the
                  country. </p>
              </div>
              <div class="mbr-flex">
                <span class="fa fa-check mbr-iconfont"><svg width="100%" height="100%" viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                    <path
                      d="M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z">
                    </path>
                  </svg></span>
                <p class="mbr-fonts-style item__service display-4">Implementation of advanced
                  technology, continuous innovation and a commitment to service quality gives Chirait
                  an upper hand in meeting the most sophisticated demands of corporate customers in
                  India.</p>
              </div>
            </div>


          </div>
        </div>

        <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-6 image">

          <amp-img src="../assets/image/Internship.jpg" alt="image" height="550" width="600" layout="responsive"
            class="mobirise-loader">
            <div placeholder="" class="placeholder">
              <div class="mobirise-spinner">
                <em></em>
                <em></em>
                <em></em>
              </div>
            </div>

          </amp-img>
        </div>
      </div>
    </div>
  </section>

  <!-- -------- About Us End ---------------- -->



  <!-- ----------- OUR SERVICES ----------------- -->

  <section class="features8 cid-rIQ9glm6RI" id="features8-m">



    <div class="container">
      <div class="mbr-row mbr-align-center">
        <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-5 mbr-flex text__block align-left">
          <div class="title__block">

            <h3 class="mbr-section-title mbr-bold mbr-fonts-style mbr-black display-2"><span
                style="font-weight: normal;"></span> OUR SERVICES</h3>
            <span class="divider"></span>
            <!-- <h4 class="mbr-section-subtitle mbr-fonts-style mbr-bold display-4">Lorem ipsum dolor sit amet,
              consectetur adipisicing elit. Illo, doloremque!</h4> -->


          </div>
        </div>
        <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-7">
          <div class="mbr-row">
            <div class="mbr-col-sm-12 mbr-col-md-6 mbr-col-lg-6">
              <div class="inner-item rounded__10 mbr-flex">
                <div class="mbr-overlay rounded__10"></div>
                <amp-img src="../assets/image/computer01.jpg" alt="image" height="259" width="388.8229426433915"
                  layout="responsive" class="mobirise-loader">
                  <div placeholder="" class="placeholder">
                    <div class="mobirise-spinner">
                      <em></em>
                      <em></em>
                      <em></em>
                    </div>
                  </div>

                </amp-img>
                <div class="text__box mbr-flex mbr-column">
                  <div class="service__icon item1 rounded__10 mbr-flex mbr-jc-c mbr-align-center">
                    <span class="mbr-iconfont mbr-iconfont-btn align-center fa-bar-chart-o fa"><svg width="30"
                        height="30" viewBox="0 0 2048 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                        <path
                          d="M640 896v512h-256v-512h256zm384-512v1024h-256v-1024h256zm1024 1152v128h-2048v-1536h128v1408h1920zm-640-896v768h-256v-768h256zm384-384v1152h-256v-1152h256z">
                        </path>
                      </svg></span>
                  </div>
                  <div class="card-text">
                    <h3 class="mbr-fonts-style card__title mbr-semibold mbr-black display-5">
                      Products</h3>
                    <p class="mbr-fonts-style mbr-regular card__text mbr-mt-1 display-4">Chira has
                      an exclusive and authorized business partnership with international brands.
                    </p>
                  </div>
                </div>
              </div>
              <div class="inner-item rounded__10 mbr-flex">
                <div class="mbr-overlay rounded__10"></div>
                <amp-img src="../assets/image/Network-server.jpg" alt="image" height="259" width="388.8229426433915"
                  layout="responsive" class="mobirise-loader">
                  <div placeholder="" class="placeholder">
                    <div class="mobirise-spinner">
                      <em></em>
                      <em></em>
                      <em></em>
                    </div>
                  </div>

                </amp-img>
                <div class="text__box mbr-flex mbr-column">
                  <div class="service__icon item3 rounded__10 mbr-flex mbr-jc-c mbr-align-center">
                    <span class="mbr-iconfont mbr-iconfont-btn align-center fa-heartbeat fa"><svg width="30" height="30"
                        viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                        <path
                          d="M1280 1024h305q-5 6-10 10.5t-9 7.5l-3 4-623 600q-18 18-44 18t-44-18l-624-602q-5-2-21-20h369q22 0 39.5-13.5t22.5-34.5l70-281 190 667q6 20 23 33t39 13q21 0 38-13t23-33l146-485 56 112q18 35 57 35zm512-428q0 145-103 300h-369l-111-221q-8-17-25.5-27t-36.5-8q-45 5-56 46l-129 430-196-686q-6-20-23.5-33t-39.5-13-39 13.5-22 34.5l-116 464h-423q-103-155-103-300 0-220 127-344t351-124q62 0 126.5 21.5t120 58 95.5 68.5 76 68q36-36 76-68t95.5-68.5 120-58 126.5-21.5q224 0 351 124t127 344z">
                        </path>
                      </svg></span>
                  </div>
                  <div class="card-text">
                    <h3 class="mbr-fonts-style card__title mbr-semibold mbr-black display-5">
                      Services</h3>
                    <p class="mbr-fonts-style mbr-regular card__text mbr-mt-1 display-4">Innovative
                      range of services helps you leverage your business & sustain a competitive
                      advantage.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="mbr-col-sm-12 mbr-col-md-6 mbr-col-lg-6">
              <div class="inner-item rounded__10 mbr-flex push">
                <div class="mbr-overlay rounded__10"></div>
                <amp-img src="../assets/image/robotics.jpg" alt="image" height="259" width="388.8229426433915"
                  layout="responsive" class="mobirise-loader">
                  <div placeholder="" class="placeholder">
                    <div class="mobirise-spinner">
                      <em></em>
                      <em></em>
                      <em></em>
                    </div>
                  </div>

                </amp-img>
                <div class="text__box mbr-flex mbr-column">
                  <div class="service__icon item2 rounded__10 mbr-flex mbr-jc-c mbr-align-center">
                    <span class="mbr-iconfont mbr-iconfont-btn align-center fa-stethoscope fa"><svg width="30"
                        height="30" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                        <path
                          d="M1472 704q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128 0q0 62-35.5 111t-92.5 70v395q0 159-131.5 271.5t-316.5 112.5-316.5-112.5-131.5-271.5v-132q-164-20-274-128t-110-252v-512q0-26 19-45t45-19q6 0 16 2 17-30 47-48t65-18q53 0 90.5 37.5t37.5 90.5-37.5 90.5-90.5 37.5q-33 0-64-18v402q0 106 94 181t226 75 226-75 94-181v-402q-31 18-64 18-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5q35 0 65 18t47 48q10-2 16-2 26 0 45 19t19 45v512q0 144-110 252t-274 128v132q0 106 94 181t226 75 226-75 94-181v-395q-57-21-92.5-70t-35.5-111q0-80 56-136t136-56 136 56 56 136z">
                        </path>
                      </svg></span>
                  </div>
                  <div class="card-text">
                    <h3 class="mbr-fonts-style card__title mbr-semibold mbr-black display-5">
                      Solutions
                    </h3>
                    <p class="mbr-fonts-style mbr-regular card__text mbr-mt-1 display-4">
                      Comprehensive solutions based on PC, Laptop, Server etc which forms the
                      client side computing</p>
                  </div>
                </div>
              </div>
              <div class="inner-item rounded__10 mbr-flex">
                <div class="mbr-overlay rounded__10"></div>
                <amp-img src="../assets/image/Internship.jpg" alt="image" height="259" width="388.8229426433915"
                  layout="responsive" class="mobirise-loader">
                  <div placeholder="" class="placeholder">
                    <div class="mobirise-spinner">
                      <em></em>
                      <em></em>
                      <em></em>
                    </div>
                  </div>

                </amp-img>

                <div class="text__box mbr-flex mbr-column">
                  <div class="service__icon item4 rounded__10 mbr-flex mbr-jc-c mbr-align-center">
                    <span class="mbr-iconfont mbr-iconfont-btn align-center fa-edit fa"><svg width="30" height="30"
                        viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                        <path
                          d="M888 1184l116-116-152-152-116 116v56h96v96h56zm440-720q-16-16-33 1l-350 350q-17 17-1 33t33-1l350-350q17-17 1-33zm80 594v190q0 119-84.5 203.5t-203.5 84.5h-832q-119 0-203.5-84.5t-84.5-203.5v-832q0-119 84.5-203.5t203.5-84.5h832q63 0 117 25 15 7 18 23 3 17-9 29l-49 49q-14 14-32 8-23-6-45-6h-832q-66 0-113 47t-47 113v832q0 66 47 113t113 47h832q66 0 113-47t47-113v-126q0-13 9-22l64-64q15-15 35-7t20 29zm-96-738l288 288-672 672h-288v-288zm444 132l-92 92-288-288 92-92q28-28 68-28t68 28l152 152q28 28 28 68t-28 68z">
                        </path>
                      </svg></span>
                  </div>
                  <div class="card-text">
                    <h3 class="mbr-fonts-style card__title mbr-semibold mbr-black display-5">
                      Internship</h3>
                    <p class="mbr-fonts-style mbr-regular card__text mbr-mt-1 display-4">As an
                      intern, you'll get exposure to real projects and gain real world experience
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <!-- -------------- OUR SERVICES ENDS ------------------ -->




  <section class="title1 cid-rIUGAjJiTw" id="title1-u">



    <div class="container">
      <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-12 mbr-px-0 align-center">

        <div class="title__block align-left align-center">
          <h3 class="mbr-bold title mbr-section-title mbr-fonts-style mbr-black display-2">
            <!-- <span style="font-weight: normal;">EVALUATION OF THE CURRENT SAFETY </span> -->
            <div>Why Choose us</div>
          </h3>


        </div>
      </div>
    </div>
  </section>


  <section class="features9 cid-rIPuT06XAg" id="features9-8">
    <div class="container">
      <div class="mbr-row mbr-jc-c">
        <div class="card mbr-col-sm-12 mbr-col-md-8 mbr-col-lg-4">
          <div class="card-wrapper mbr-flex align-left">
            <div class="card-img">
              <div class="align-center mbr-flex mbr-jc-c icon__item rounded__7">
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-flask fa"><svg
                    width="30" height="30" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1591 1448q56 89 21.5 152.5t-140.5 63.5h-1152q-106 0-140.5-63.5t21.5-152.5l503-793v-399h-64q-26 0-45-19t-19-45 19-45 45-19h512q26 0 45 19t19 45-19 45-45 19h-64v399zm-779-725l-272 429h712l-272-429-20-31v-436h-128v436z">
                    </path>
                  </svg></span>
              </div>
            </div>
            <div class="card-box">
              <h4 class="card-subtitle mbr-fonts-style mbr-semibold mbr-black display-6">Reliability &
                Expertise</h4>
              <p class="card-text mbr-text mbr-mt-1 mbr-fonts-style display-4">We send our trusted and
                skilled employees to address your issues. We never send third party vendors to take care
                of your systems.</p>

            </div>
          </div>
        </div>
        <div class="card mbr-col-sm-12 mbr-col-md-8 mbr-col-lg-4">
          <div class="card-wrapper mbr-flex align-left">
            <div class="card-img">
              <div class="align-center mbr-flex mbr-jc-c icon__item rounded__7">
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-street-view fa"><svg
                    width="30" height="30" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1536 1536q0 63-61.5 113.5t-164 81-225 46-253.5 15.5-253.5-15.5-225-46-164-81-61.5-113.5q0-49 33-88.5t91-66.5 118-44.5 131-29.5q26-5 48 10.5t26 41.5q5 26-10.5 48t-41.5 26q-58 10-106 23.5t-76.5 25.5-48.5 23.5-27.5 19.5-8.5 12q3 11 27 26.5t73 33 114 32.5 160.5 25 201.5 10 201.5-10 160.5-25 114-33 73-33.5 27-27.5q-1-4-8.5-11t-27.5-19-48.5-23.5-76.5-25-106-23.5q-26-4-41.5-26t-10.5-48q4-26 26-41.5t48-10.5q71 12 131 29.5t118 44.5 91 66.5 33 88.5zm-384-896v384q0 26-19 45t-45 19h-64v384q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-384h-64q-26 0-45-19t-19-45v-384q0-53 37.5-90.5t90.5-37.5h384q53 0 90.5 37.5t37.5 90.5zm-96-384q0 93-65.5 158.5t-158.5 65.5-158.5-65.5-65.5-158.5 65.5-158.5 158.5-65.5 158.5 65.5 65.5 158.5z">
                    </path>
                  </svg></span>
              </div>
            </div>
            <div class="card-box">
              <h4 class="card-subtitle mbr-fonts-style mbr-semibold mbr-black display-6">Quality Assurance
              </h4>
              <p class="card-text mbr-text mbr-mt-1 mbr-fonts-style display-4">We provide quality service
                and genuine spare parts with warranty. If your system has the same trouble in the next
                30 days, we provide free service.</p>

            </div>
          </div>
        </div>
        <div class="card mbr-col-sm-12 mbr-col-md-8 mbr-col-lg-4">
          <div class="card-wrapper mbr-flex align-left">
            <div class="card-img">
              <div class="align-center mbr-flex mbr-jc-c icon__item rounded__7">
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-heartbeat fa"><svg
                    width="30" height="30" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1280 1024h305q-5 6-10 10.5t-9 7.5l-3 4-623 600q-18 18-44 18t-44-18l-624-602q-5-2-21-20h369q22 0 39.5-13.5t22.5-34.5l70-281 190 667q6 20 23 33t39 13q21 0 38-13t23-33l146-485 56 112q18 35 57 35zm512-428q0 145-103 300h-369l-111-221q-8-17-25.5-27t-36.5-8q-45 5-56 46l-129 430-196-686q-6-20-23.5-33t-39.5-13-39 13.5-22 34.5l-116 464h-423q-103-155-103-300 0-220 127-344t351-124q62 0 126.5 21.5t120 58 95.5 68.5 76 68q36-36 76-68t95.5-68.5 120-58 126.5-21.5q224 0 351 124t127 344z">
                    </path>
                  </svg></span>
              </div>
            </div>
            <div class="card-box">
              <h4 class="card-subtitle mbr-fonts-style mbr-semibold mbr-black display-6">Cost Approval
              </h4>
              <p class="card-text mbr-text mbr-mt-1 mbr-fonts-style display-4">We ensure that you approve
                the charges before we begin work. You can pay us by cash, card, online transfer or
                PayTM.</p>

            </div>
          </div>
        </div>
      </div>
    </div>

  </section>

  <!-- ------------- Testimonials Start ---------------- -->

  <section class="title2 cid-rIUGR3uSrn" id="title2-v">



    <div class="container">
      <div class="mbr-col-sm-12 mbr-px-0 mbr-col-lg-12 mbr-col-md-12">
        <div class="mbr-col-sm-12 right mbr-flex">
          <!-- <div class="service__icon align-left">
          <span class="mbr-iconfont mbr-iconfont-btn mbr-flex mbr-align-center mbr-jc-c fa-briefcase fa">
            <svg width="40" height="40" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
              <path
                d="M1536 1399q0 109-62.5 187t-150.5 78h-854q-88 0-150.5-78t-62.5-187q0-85 8.5-160.5t31.5-152 58.5-131 94-89 134.5-34.5q131 128 313 128t313-128q76 0 134.5 34.5t94 89 58.5 131 31.5 152 8.5 160.5zm-256-887q0 159-112.5 271.5t-271.5 112.5-271.5-112.5-112.5-271.5 112.5-271.5 271.5-112.5 271.5 112.5 112.5 271.5z">
              </path>
            </svg>
          </span>
        </div> -->
          <div class="title__block mbr-text">
            <h3 class="title mbr-section-title mbr-bold mbr-fonts-style mbr-black display-2">
              Testimonials
            </h3>
            <span class="divider"></span>
            <!-- <h4 class="mbr-section-subtitle mbr-fonts-style display-4">Lorem ipsum dolor sit amet,
                            consectetur adipisicing elit. <br>Eos tempora quibusdam provident, iure facere, culpa
                            consequatur neque vel ipsum at.</h4> -->
          </div>

        </div>
      </div>
    </div>

  </section>


  <section class="testimonials1 cid-rIyFh3WtEt" id="testimonials1-z">



    <div class="container-fluid">
      <div class="mbr-row">
        <div class="card mbr-col-sm-12 mbr-col-md-6 mbr-col-lg-3">
          <div class="card-wrapper rounded__7 mbr-flex mbr-column">
            <div class="card-img">
              <div class="image">
                <amp-img src="../assets/images/face1.jpg" alt="image" height="70" width="70" layout="responsive"
                  class="mobirise-loader">
                  <div placeholder="" class="placeholder">
                    <div class="mobirise-spinner">
                      <em></em>
                      <em></em>
                      <em></em>
                    </div>
                  </div>
                  <a href="#"></a>
                </amp-img>
              </div>
            </div>
            <div class="card-box">
              <div class="stars">
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                    width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                    </path>
                  </svg></span>
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                    width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                    </path>
                  </svg></span>
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                    width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                    </path>
                  </svg></span>
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                    width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                    </path>
                  </svg></span>
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                    width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                    </path>
                  </svg></span>
              </div>

              <p class="card-text mbr-fonts-style mbr-black display-4">Click on the text in the block and
                enter the text you want to show.</p>
              <p class="descr__text mbr-fonts-style mbr-black mbr-semibold display-4">Liza B.</p>

            </div>
          </div>
        </div>
        <div class="card mbr-col-sm-12 mbr-col-md-6 mbr-col-lg-3">
          <div class="card-wrapper rounded__7 mbr-flex mbr-column">
            <div class="card-img">
              <div class="image">
                <amp-img src="../assets/images/face3.jpg" alt="image" height="70" width="70" layout="responsive"
                  class="mobirise-loader">
                  <div placeholder="" class="placeholder">
                    <div class="mobirise-spinner">
                      <em></em>
                      <em></em>
                      <em></em>
                    </div>
                  </div>
                  <a href="#"></a>
                </amp-img>
              </div>
            </div>
            <div class="card-box">
              <div class="stars">
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                    width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                    </path>
                  </svg></span>
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                    width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                    </path>
                  </svg></span>
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                    width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                    </path>
                  </svg></span>
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                    width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                    </path>
                  </svg></span>
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star-o fa"><svg
                    width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1201 1004l306-297-422-62-189-382-189 382-422 62 306 297-73 421 378-199 377 199zm527-357q0 22-26 48l-363 354 86 500q1 7 1 20 0 50-41 50-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                    </path>
                  </svg></span>
              </div>

              <p class="card-text mbr-fonts-style mbr-black display-4">Click on the text in the block and
                enter the text you want to show.</p>
              <p class="descr__text mbr-fonts-style mbr-black mbr-semibold display-4">John D.</p>

            </div>
          </div>
        </div>
        <div class="card mbr-col-sm-12 mbr-col-md-6 mbr-col-lg-3">
          <div class="card-wrapper rounded__7 mbr-flex mbr-column">
            <div class="card-img">
              <div class="image">
                <amp-img src="../assets/images/face5.jpg" alt="image" height="70" width="70" layout="responsive"
                  class="mobirise-loader">
                  <div placeholder="" class="placeholder">
                    <div class="mobirise-spinner">
                      <em></em>
                      <em></em>
                      <em></em>
                    </div>
                  </div>
                  <a href="#"></a>
                </amp-img>
              </div>
            </div>
            <div class="card-box">
              <div class="stars">
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                    width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                    </path>
                  </svg></span>
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                    width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                    </path>
                  </svg></span>
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                    width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                    </path>
                  </svg></span>
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star-o fa"><svg
                    width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1201 1004l306-297-422-62-189-382-189 382-422 62 306 297-73 421 378-199 377 199zm527-357q0 22-26 48l-363 354 86 500q1 7 1 20 0 50-41 50-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                    </path>
                  </svg></span>
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star-o fa"><svg
                    width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1201 1004l306-297-422-62-189-382-189 382-422 62 306 297-73 421 378-199 377 199zm527-357q0 22-26 48l-363 354 86 500q1 7 1 20 0 50-41 50-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                    </path>
                  </svg></span>
              </div>

              <p class="card-text mbr-fonts-style mbr-black display-4">Click on the text in the block and
                enter the text you want to show.</p>
              <p class="descr__text mbr-fonts-style mbr-black mbr-semibold display-4">Eric P.</p>

            </div>
          </div>
        </div>
        <div class="card mbr-col-sm-12 mbr-col-md-6 mbr-col-lg-3 last-child">
          <div class="card-wrapper rounded__7 mbr-flex mbr-column">
            <div class="card-img">
              <div class="image">
                <amp-img src="../assets/images/face6.jpg" alt="image" height="70" width="70" layout="responsive"
                  class="mobirise-loader">
                  <div placeholder="" class="placeholder">
                    <div class="mobirise-spinner">
                      <em></em>
                      <em></em>
                      <em></em>
                    </div>
                  </div>
                  <a href="#"></a>
                </amp-img>
              </div>
            </div>
            <div class="card-box">
              <div class="stars">
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                    width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                    </path>
                  </svg></span>
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                    width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                    </path>
                  </svg></span>
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                    width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                    </path>
                  </svg></span>
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                    width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                    </path>
                  </svg></span>
                <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star-o fa"><svg
                    width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1201 1004l306-297-422-62-189-382-189 382-422 62 306 297-73 421 378-199 377 199zm527-357q0 22-26 48l-363 354 86 500q1 7 1 20 0 50-41 50-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                    </path>
                  </svg></span>
              </div>

              <p class="card-text mbr-fonts-style mbr-black display-4">Click on the text in the block and
                enter the text you want to show.</p>
              <p class="descr__text mbr-fonts-style mbr-black mbr-semibold display-4">Anna B.</p>

            </div>
          </div>
        </div>
      </div>
    </div>

  </section>



  <!-- --------------------- Testimonials Ends -------------------------- -->




  <!-- -----------------Clients Start -------------------- -->


  <section class="title2 cid-rIUGR3uSrn" style="background-color: #ffffff;" id="title2-v">



    <div class="container">
      <div class="mbr-col-sm-12 mbr-px-0 mbr-col-lg-12 mbr-col-md-12">
        <div class="mbr-col-sm-12 right mbr-flex">
          <!-- <div class="service__icon align-left">
          <span class="mbr-iconfont mbr-iconfont-btn mbr-flex mbr-align-center mbr-jc-c fa-comments-o fa"><svg width="40"
              height="40" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
              <path
                d="M704 384q-153 0-286 52t-211.5 141-78.5 191q0 82 53 158t149 132l97 56-35 84q34-20 62-39l44-31 53 10q78 14 153 14 153 0 286-52t211.5-141 78.5-191-78.5-191-211.5-141-286-52zm0-128q191 0 353.5 68.5t256.5 186.5 94 257-94 257-256.5 186.5-353.5 68.5q-86 0-176-16-124 88-278 128-36 9-86 16h-3q-11 0-20.5-8t-11.5-21q-1-3-1-6.5t.5-6.5 2-6l2.5-5 3.5-5.5 4-5 4.5-5 4-4.5q5-6 23-25t26-29.5 22.5-29 25-38.5 20.5-44q-124-72-195-177t-71-224q0-139 94-257t256.5-186.5 353.5-68.5zm822 1169q10 24 20.5 44t25 38.5 22.5 29 26 29.5 23 25q1 1 4 4.5t4.5 5 4 5 3.5 5.5l2.5 5 2 6 .5 6.5-1 6.5q-3 14-13 22t-22 7q-50-7-86-16-154-40-278-128-90 16-176 16-271 0-472-132 58 4 88 4 161 0 309-45t264-129q125-92 192-212t67-254q0-77-23-152 129 71 204 178t75 230q0 120-71 224.5t-195 176.5z">
              </path>
            </svg></span> </div> -->

          <div class="title__block mbr-text">
            <h3 class="title mbr-section-title mbr-bold mbr-fonts-style mbr-black display-2">
              Our Clients
            </h3>
            <span class="divider"></span>
            <!-- <h4 class="mbr-section-subtitle mbr-fonts-style display-4">Lorem ipsum dolor sit amet,
                            consectetur adipisicing elit. <br>Eos tempora quibusdam provident, iure facere, culpa
                            consequatur neque vel ipsum at.</h4> -->
          </div>

        </div>
      </div>
    </div>

  </section>



  <section class="cid-rIyELyIP8u" id="clients-u">
    <!-- Block parameters controls (Blue "Gear" panel) -->

    <!-- End block parameters -->
    <div class="images-container container">
      <div class="mbr-row mbr-jc-c">

        <div class="cards__block mbr-flex flex-wrap mbr-jc-c">
          <div class="card mbr-col-md-4 mbr-col-lg-2 mbr-flex mbr-jc-c mbr-align-center">
            <amp-img src="../assets/images/1.png" alt="image" height="120" width="120" layout="responsive"
              class="mobirise-loader">
              <div placeholder="" class="placeholder">
                <div class="mobirise-spinner">
                  <em></em>
                  <em></em>
                  <em></em>
                </div>
              </div>

            </amp-img>
          </div>
          <div class="card mbr-col-md-4 mbr-col-lg-2 mbr-flex mbr-jc-c mbr-align-center">
            <amp-img src="../assets/images/2.png" alt="image" height="120" width="120" layout="responsive"
              class="mobirise-loader">
              <div placeholder="" class="placeholder">
                <div class="mobirise-spinner">
                  <em></em>
                  <em></em>
                  <em></em>
                </div>
              </div>

            </amp-img>
          </div>
          <div class="card mbr-col-md-4 mbr-col-lg-2 mbr-flex mbr-jc-c mbr-align-center">
            <amp-img src="../assets/images/3.png" alt="image" height="120" width="120" layout="responsive"
              class="mobirise-loader">
              <div placeholder="" class="placeholder">
                <div class="mobirise-spinner">
                  <em></em>
                  <em></em>
                  <em></em>
                </div>
              </div>

            </amp-img>
          </div>
          <div class="card mbr-col-md-4 mbr-col-lg-2 mbr-flex mbr-jc-c mbr-align-center">
            <amp-img src="../assets/images/4.png" alt="image" height="120" width="120" layout="responsive"
              class="mobirise-loader">
              <div placeholder="" class="placeholder">
                <div class="mobirise-spinner">
                  <em></em>
                  <em></em>
                  <em></em>
                </div>
              </div>

            </amp-img>
          </div>
          <div class="card mbr-col-md-4 mbr-col-lg-2 mbr-flex mbr-jc-c mbr-align-center">
            <amp-img src="../assets/images/5.png" alt="image" height="120" width="120" layout="responsive"
              class="mobirise-loader">
              <div placeholder="" class="placeholder">
                <div class="mobirise-spinner">
                  <em></em>
                  <em></em>
                  <em></em>
                </div>
              </div>

            </amp-img>
          </div>
          <div class="card mbr-col-md-4 mbr-col-lg-2 mbr-flex mbr-jc-c mbr-align-center">
            <amp-img src="../assets/images/6.png" alt="image" height="120" width="120" layout="responsive"
              class="mobirise-loader">
              <div placeholder="" class="placeholder">
                <div class="mobirise-spinner">
                  <em></em>
                  <em></em>
                  <em></em>
                </div>
              </div>

            </amp-img>
          </div>


        </div>
      </div>
    </div>
  </section>


  <!-- -----------------Clients Ends -------------------- -->




  <!-- ----------------- Contact Us Start --------------------- -->


  <section class="title2 cid-rIUGR3uSrn" style="background-color: #f0f3fc;" id="contactUsForm">

    <div class="container">
      <div class="mbr-col-sm-12 mbr-px-0 mbr-col-lg-12 mbr-col-md-12">
        <div class="mbr-col-sm-12 right mbr-flex">
          <!-- <div class="service__icon align-left">
          <span class="mbr-iconfont mbr-iconfont-btn mbr-flex mbr-align-center mbr-jc-c fa-group fa"><svg width="20"
              height="20" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
              <path
                d="M657 896q-162 5-265 128h-134q-82 0-138-40.5t-56-118.5q0-353 124-353 6 0 43.5 21t97.5 42.5 119 21.5q67 0 133-23-5 37-5 66 0 139 81 256zm1071 637q0 120-73 189.5t-194 69.5h-874q-121 0-194-69.5t-73-189.5q0-53 3.5-103.5t14-109 26.5-108.5 43-97.5 62-81 85.5-53.5 111.5-20q10 0 43 21.5t73 48 107 48 135 21.5 135-21.5 107-48 73-48 43-21.5q61 0 111.5 20t85.5 53.5 62 81 43 97.5 26.5 108.5 14 109 3.5 103.5zm-1024-1277q0 106-75 181t-181 75-181-75-75-181 75-181 181-75 181 75 75 181zm704 384q0 159-112.5 271.5t-271.5 112.5-271.5-112.5-112.5-271.5 112.5-271.5 271.5-112.5 271.5 112.5 112.5 271.5zm576 225q0 78-56 118.5t-138 40.5h-134q-103-123-265-128 81-117 81-256 0-29-5-66 66 23 133 23 59 0 119-21.5t97.5-42.5 43.5-21q124 0 124 353zm-128-609q0 106-75 181t-181 75-181-75-75-181 75-181 181-75 181 75 75 181z">
              </path>
            </svg></span> </div> -->

          <div class="title__block mbr-text">
            <h3 class="title mbr-section-title mbr-bold mbr-fonts-style mbr-black display-2">
              Contact Us
            </h3>
            <span class="divider"></span>
            <!-- <h4 class="mbr-section-subtitle mbr-fonts-style display-4">Lorem ipsum dolor sit amet,
                            consectetur adipisicing elit. <br>Eos tempora quibusdam provident, iure facere, culpa
                            consequatur neque vel ipsum at.</h4> -->
          </div>

        </div>
      </div>
    </div>

  </section>


  <section class="form1 cid-rIyETsYeLm" style="background-color: #f0f3fc;" id="form1-w">

    <!-- Block parameters controls (Blue "Gear" panel) -->

    <div class="container">
      <div class="form__wrap rounded__10">
        <div class="mbr-column mbr-flex mbr-jc-c" data-form-type="formoid">
          <!--Formbuilder Form-->
          <form class="mbr-form" [formGroup]="freeEnquiryForm" #form="ngForm" (ngSubmit)="onSubmit()">

            <div class="dragArea mbr-row">
              <div class="mbr-col-lg-4 mbr-col-md-6 mbr-col-sm-12 field ">
                <label class="form-control-label mbr-fonts-style display-4">First name
                  *</label>
                <span class="mbr-iconfont fa-user fa"><svg width="100%" height="100%" viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                    <path
                      d="M1536 1399q0 109-62.5 187t-150.5 78h-854q-88 0-150.5-78t-62.5-187q0-85 8.5-160.5t31.5-152 58.5-131 94-89 134.5-34.5q131 128 313 128t313-128q76 0 134.5 34.5t94 89 58.5 131 31.5 152 8.5 160.5zm-256-887q0 159-112.5 271.5t-271.5 112.5-271.5-112.5-112.5-271.5 112.5-271.5 271.5-112.5 271.5 112.5 112.5 271.5z">
                    </path>
                  </svg></span>
                <div class="errorMessage">
                  <p *ngIf="(freeEnquiryForm.controls.firstName.touched) && freeEnquiryForm.controls.firstName.errors?.required"
                    class="text-danger">
                    Name is required.
                  </p>
                  <p *ngIf="freeEnquiryForm.get('firstName').touched && (freeEnquiryForm.get('firstName').errors?.pattern || freeEnquiryForm.get('firstName').errors?.minlength || freeEnquiryForm.get('firstName').errors?.maxlength)"
                    class="text-danger">This is invalid name!</p>
                </div>
                <input type="text" formControlName="firstName" placeholder="Anna" class="field-input display-4"
                  required="required" value="">
              </div>

              <div class="mbr-col-lg-4 mbr-col-md-6 mbr-col-sm-12 field ">
                <label class="form-control-label mbr-fonts-style display-4">Last name
                  *</label>

                <span class="mbr-iconfont fa-user fa"><svg width="100%" height="100%" viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                    <path
                      d="M1536 1399q0 109-62.5 187t-150.5 78h-854q-88 0-150.5-78t-62.5-187q0-85 8.5-160.5t31.5-152 58.5-131 94-89 134.5-34.5q131 128 313 128t313-128q76 0 134.5 34.5t94 89 58.5 131 31.5 152 8.5 160.5zm-256-887q0 159-112.5 271.5t-271.5 112.5-271.5-112.5-112.5-271.5 112.5-271.5 271.5-112.5 271.5 112.5 112.5 271.5z">
                    </path>
                  </svg></span>

                <div class="errorMessage">
                  <p *ngIf="(freeEnquiryForm.controls.lastName.touched) && freeEnquiryForm.controls.lastName.errors?.required"
                    class="text-danger">
                    Name is required.
                  </p>
                  <p *ngIf="freeEnquiryForm.get('lastName').touched && (freeEnquiryForm.get('lastName').errors?.pattern || freeEnquiryForm.get('lastName').errors?.minlength || freeEnquiryForm.get('lastName').errors?.maxlength)"
                    class="text-danger">This is invalid name!</p>
                </div>
                <input type="text" placeholder="Brown" formControlName="lastName" class="field-input display-4"
                  required="required" value="">
              </div>
              <div class="mbr-col-lg-4 mbr-col-md-6 mbr-col-sm-12 field">
                <label class="form-control-label mbr-fonts-style display-4">What is your inquiry about?
                  *</label>
                <span class="mbr-iconfont fa-info fa"><svg width="100%" height="100%" viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                    <path
                      d="M1216 1344v128q0 26-19 45t-45 19h-512q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h64v-384h-64q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h384q26 0 45 19t19 45v576h64q26 0 45 19t19 45zm-128-1152v192q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-192q0-26 19-45t45-19h256q26 0 45 19t19 45z">
                    </path>
                  </svg></span>
                <div class="errorMessage">
                  <p *ngIf="(freeEnquiryForm.controls.about.touched) && freeEnquiryForm.controls.about.errors?.required"
                    class="text-danger">
                    Select is required.
                  </p>
                </div>
                <select data-form-field="select" formControlName="about" class="field-input display-4"
                  required="required">
                  <option value="Please Select">Please Select</option>
                  <option value="First Choice">First Choice</option>
                  <option value="Second Choice">Second Choice</option>
                  <option value="Third Choice">Third Choice</option>
                </select>
              </div>
              <div class="mbr-col-lg-4 mbr-col-md-6 mbr-col-sm-12 field">
                <label class="form-control-label mbr-fonts-style display-4">Phone
                  number*</label>
                <span class="mbr-iconfont fa-phone fa"><svg width="100%" height="100%" viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                    <path
                      d="M1600 1240q0 27-10 70.5t-21 68.5q-21 50-122 106-94 51-186 51-27 0-53-3.5t-57.5-12.5-47-14.5-55.5-20.5-49-18q-98-35-175-83-127-79-264-216t-216-264q-48-77-83-175-3-9-18-49t-20.5-55.5-14.5-47-12.5-57.5-3.5-53q0-92 51-186 56-101 106-122 25-11 68.5-21t70.5-10q14 0 21 3 18 6 53 76 11 19 30 54t35 63.5 31 53.5q3 4 17.5 25t21.5 35.5 7 28.5q0 20-28.5 50t-62 55-62 53-28.5 46q0 9 5 22.5t8.5 20.5 14 24 11.5 19q76 137 174 235t235 174q2 1 19 11.5t24 14 20.5 8.5 22.5 5q18 0 46-28.5t53-62 55-62 50-28.5q14 0 28.5 7t35.5 21.5 25 17.5q25 15 53.5 31t63.5 35 54 30q70 35 76 53 3 7 3 21z">
                    </path>
                  </svg></span>
                <div class="errorMessage">
                  <p *ngIf="(freeEnquiryForm.controls.phoneNumber.touched) && freeEnquiryForm.controls.phoneNumber.errors?.required"
                    class="text-danger">
                    Phone number is required.
                  </p>
                  <p *ngIf="freeEnquiryForm.get('phoneNumber').touched && (freeEnquiryForm.get('phoneNumber').errors?.pattern || freeEnquiryForm.get('phoneNumber').errors?.minlength || freeEnquiryForm.get('phoneNumber').errors?.maxlength)"
                    class="text-danger">This is invalid Phone number!</p>
                </div>
                <input type="tel" placeholder="Full Number" formControlName="phoneNumber" class="field-input display-4"
                  value="">
              </div>
              <div class="mbr-col-lg-4 mbr-col-md-6 mbr-col-sm-12 field">
                <label class="form-control-label mbr-fonts-style display-4">
                  Email Id *
                </label>

                <span class="mbr-iconfont fa-envelope fa"><svg width="100%" height="100%" viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                    <path
                      d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z">
                    </path>
                  </svg></span>
                <div class="errorMessage">
                  <p *ngIf="(freeEnquiryForm.controls.emailId.touched) && freeEnquiryForm.controls.emailId.errors?.required"
                    class="text-danger">
                    Email Id is required.
                  </p>
                  <p *ngIf="freeEnquiryForm.get('emailId').touched && (freeEnquiryForm.get('emailId').errors?.pattern || freeEnquiryForm.get('emailId').errors?.minlength || freeEnquiryForm.get('emailId').errors?.maxlength)"
                    class="text-danger">This is invalid Email Id!</p>
                </div>
                <input type="email" placeholder="name@mail.com" formControlName="emailId" class="field-input display-4"
                  required="required" value="">
              </div>
              <div class="mbr-col-lg-4 mbr-col-md-6 mbr-col-sm-12 field field__btn mbr-flex"><button type="submit"
                  [disabled]="freeEnquiryForm.invalid" class="btn btn-sm btn-form btn-primary display-4">Get a free
                  consultation</button></div>
            </div>
          </form>
          <!--Formbuilder Form-->
        </div>
      </div>
    </div>
  </section>


  <!-- ------------------- Contact Us Ends ------------------- -->


  <!-- --------------- Map Start --------------------- -->

  <section class="map cid-rIyEOg8tB7" id="map-v">

    <div class="mbr-row mbr-jc-c">
      <div class="mbr-px-0 mbr-col mbr-col-lg-12">
        <div class="google-map">
          <amp-iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243.003369938338!2d77.53700548682032!3d12.96847358192819!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3dde43b1bdcd%3A0xa55c68d0f199cc44!2sAreRanga%20Complex!5e0!3m2!1sen!2sin!4v1646914105494!5m2!1sen!2sin"
            height="400" layout="fill" sandbox="allow-scripts allow-same-origin allow-popups" frameborder="0"
            class="mobirise-loader">
            <div placeholder="" class="placeholder">
              <div class="mobirise-spinner">
                <em></em>
                <em></em>
                <em></em>
              </div>
            </div>
          </amp-iframe>
        </div>

      </div>
    </div>
  </section>


  <section class="footer1 cid-rIQ7UaoDAW" id="footer1-i">
    <div class="container">
      <div class="mbr-row footer-wrap mbr-jc-c">
        <!-- <div class="image-block align-left mbr-col-sm-12 mbr-col-lg-2 mbr-col-md-4">
              <amp-img src="../assets/images/logo-200x200.png" layout="responsive" width="130" height="130"
                  alt="Mobirise" class="mobirise-loader">
                  <div placeholder="" class="placeholder">
                      <div class="mobirise-spinner">
                          <em></em>
                          <em></em>
                          <em></em>
                      </div>
                  </div>

              </amp-img>
          </div> -->
        <div class="items-col mbr-col-sm-12 align-left mbr-col-lg-3 mbr-col-md-4">
          <h3 class="mbr-fonts-style mbr-white group-title mbr-bold display-6">SERVICES</h3>
          <div class="items mbr-white">
            <p class="item mbr-fonts-style display-4">
              AMC</p>
            <p class="item mbr-fonts-style display-4">
              Network Solution</p>
            <p class="item mbr-fonts-style display-4">
              Server Solution</p>
            <p class="item mbr-fonts-style display-4">
              Server Solution</p>
          </div>
        </div>
        <div class="items-col mbr-col-sm-12 align-left mbr-col-lg-2 mbr-col-md-4">
          <h3 class="mbr-fonts-style mbr-white group-title mbr-bold display-6"> </h3>
          <div class="items mbr-white">
            <p class="item mbr-fonts-style display-4">
              Rental</p>
            <p class="item mbr-fonts-style display-4">
              Desktop</p>
            <p class="item mbr-fonts-style display-4">
              Laptop</p>
            <p class="item mbr-fonts-style display-4">
              Printer</p>
            <p class="item mbr-fonts-style display-4">
              Server</p>
          </div>
        </div>
        <div class="items-col mbr-col-sm-12 align-left mbr-col-lg-3 mbr-col-md-4">
          <h3 class="mbr-fonts-style mbr-white group-title mbr-bold display-6">Area Served</h3>
          <div class="items mbr-white">
            <p class="item mbr-fonts-style display-4">
              Bangalore</p>
            <p class="item mbr-fonts-style display-4">
              Mysore
            </p>
            <p class="item mbr-fonts-style display-4">
              Manglore</p>
            <p class="item mbr-fonts-style display-4">
              Chennai</p>
            <p class="item mbr-fonts-style display-4">
              Hyderabad
            </p>
          </div>
        </div>
        <div class="mbr-white align-left mbr-col-sm-12  mbr-col-md-12 mbr-col-lg-4">
          <h3 class="mbr-fonts-style group-title mbr-bold display-6">
            Contacts Us</h3>
          <div class="items mbr-white">
            <p class="item mbr-fonts-style mbr-bold display-5">
              Address</p>
            <p class=" mbr-fonts-style mbr-bold mbr-black display-4">
              Chira information Technology
            </p>
            <p class=" mbr-fonts-style mbr-black display-4">
              # 2934 & 2935E, 2nd floor,"AreRanga Complex "
              Vijayanagar Club Road, Vijayanagar, Bangalore -560040.
              Above McDonald's & HDFC Bank , Near Vijayanagar Metro Station
          </div>
        </div>
      </div>
      <div class="contacts__bottom mbr-flex flex-wrap mbr-align-center">
        <div class="mbr-col-sm-12 mbr-flex mbr-align-center bottom__left mbr-col-md-6">
          <span class="mbr-iconfont mbr-iconfont-btn fa-phone fa"><svg width="20" height="20" viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg" fill="currentColor">
              <path
                d="M1600 1240q0 27-10 70.5t-21 68.5q-21 50-122 106-94 51-186 51-27 0-53-3.5t-57.5-12.5-47-14.5-55.5-20.5-49-18q-98-35-175-83-127-79-264-216t-216-264q-48-77-83-175-3-9-18-49t-20.5-55.5-14.5-47-12.5-57.5-3.5-53q0-92 51-186 56-101 106-122 25-11 68.5-21t70.5-10q14 0 21 3 18 6 53 76 11 19 30 54t35 63.5 31 53.5q3 4 17.5 25t21.5 35.5 7 28.5q0 20-28.5 50t-62 55-62 53-28.5 46q0 9 5 22.5t8.5 20.5 14 24 11.5 19q76 137 174 235t235 174q2 1 19 11.5t24 14 20.5 8.5 22.5 5q18 0 46-28.5t53-62 55-62 50-28.5q14 0 28.5 7t35.5 21.5 25 17.5q25 15 53.5 31t63.5 35 54 30q70 35 76 53 3 7 3 21z">
              </path>
            </svg></span>
          <a href="#">
            <p class="mbr-fonts-style phone mbr-semibold mbr-white display-6">080 4166 0033</p>
          </a>
        </div>
        <div class="mbr-col-sm-12 mbr-flex mbr-jc-e bottom__right mbr-col-md-6">
          <!-- SOCIAL ICON -->
          <div class="menu-social-list">
            <a href="http://www.youtube.com/channel/UCt_tncVAetpK5JeM8L-8jyw" target="_blank">
              <span class="amp-iconfont fa-youtube-play fa"><svg width="15" height="15" viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                  <path
                    d="M711 1128l484-250-484-253v503zm185-862q168 0 324.5 4.5t229.5 9.5l73 4q1 0 17 1.5t23 3 23.5 4.5 28.5 8 28 13 31 19.5 29 26.5q6 6 15.5 18.5t29 58.5 26.5 101q8 64 12.5 136.5t5.5 113.5v176q1 145-18 290-7 55-25 99.5t-32 61.5l-14 17q-14 15-29 26.5t-31 19-28 12.5-28.5 8-24 4.5-23 3-16.5 1.5q-251 19-627 19-207-2-359.5-6.5t-200.5-7.5l-49-4-36-4q-36-5-54.5-10t-51-21-56.5-41q-6-6-15.5-18.5t-29-58.5-26.5-101q-8-64-12.5-136.5t-5.5-113.5v-176q-1-145 18-290 7-55 25-99.5t32-61.5l14-17q14-15 29-26.5t31-19.5 28-13 28.5-8 23.5-4.5 23-3 17-1.5q251-18 627-18z">
                  </path>
                </svg></span>
            </a><a href="https://www.facebook.com/pages/Mobirise/1616226671953247" target="_blank">
              <span class="amp-iconfont fa-facebook-f fa"><svg width="15" height="15" viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                  <path
                    d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z">
                  </path>
                </svg></span>
            </a><a href="https://www.facebook.com/pages/Mobirise/1616226671953247" target="_blank">
              <span class="amp-iconfont fa-linkedin fa"><svg width="15" height="15" viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                  <path
                    d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z">
                  </path>
                </svg></span>
            </a><a href="https://www.facebook.com/pages/Mobirise/1616226671953247" target="_blank">
              <span class="amp-iconfont fa-vimeo-square fa"><svg width="15" height="15" viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                  <path
                    d="M1420 638q10-216-161-222-231-8-312 261 44-19 82-19 85 0 74 96-4 57-74 167t-105 110q-43 0-82-169-13-54-45-255-30-189-160-177-59 7-164 100l-81 72-81 72 52 67q76-52 87-52 57 0 107 179 15 55 45 164.5t45 164.5q68 179 164 179 157 0 383-294 220-283 226-444zm244-222v960q0 119-84.5 203.5t-203.5 84.5h-960q-119 0-203.5-84.5t-84.5-203.5v-960q0-119 84.5-203.5t203.5-84.5h960q119 0 203.5 84.5t84.5 203.5z">
                  </path>
                </svg></span>
            </a></div>
          <!-- SOCIAL ICON END -->
        </div>
      </div>
      <div class="divider__foot"></div>
      <div class="footer-container container">
        <div class="copyright align-left">
          <p class="mbr-text mbr-fonts-style mbr-white display-4">© Copyright 2021 Chira Information
            Technology - All Rights
            Reserved</p>
        </div>
      </div>
    </div>
  </section>
</div>
