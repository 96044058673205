import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'chira-frontend';
  @ViewChild('closeSidebar') closeSidebar: ElementRef;

  constructor(private route:Router){
    window.scrollTo(0, 0);
  }
  onItSolution(){
    // this.closeSidebar.nativeElement.click();
    this.route.navigate(['solutions/it-solutions']);
  }
  onHome(){
    this.closeSidebar.nativeElement.click();
    this.route.navigate(['home']);
  }
  onServices(){
    this.closeSidebar.nativeElement.click();
    this.route.navigate(['solutions/services']);
  }
  onProducts(){
    this.route.navigate(['solutions/products']);
    this.closeSidebar.nativeElement.click();

  }
  onAcademic(){
    this.closeSidebar.nativeElement.click();
    this.route.navigate(['academic']);
  }
  onContract(){
    this.closeSidebar.nativeElement.click();
    this.route.navigate(['recruitment/contract-staffing']);
  }
  onHrSolution(){
    this.closeSidebar.nativeElement.click();
    this.route.navigate(['recruitment/hr-consultant']);
  }
  onManpower(){
    this.closeSidebar.nativeElement.click();
    this.route.navigate(['recruitment/manpower']);
  }
  onPermanent(){
    this.closeSidebar.nativeElement.click();
    this.route.navigate(['recruitment/permanent-staffing']);
  }
  onrecruitment(){
    this.closeSidebar.nativeElement.click();
    this.route.navigate(['recruitment/recruitment-consultant']);
  }
}
