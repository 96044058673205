import {
  Component,
  OnInit
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';
import {
  Router
} from '@angular/router';
import {
  ServiceService
} from '../service/service.service';


@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.css']
})
export class ResumeComponent implements OnInit {
  error
  uploadResumeForm: FormGroup;

  constructor(
    private route: Router,
    private resumeService: ServiceService
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.uploadResumeForm = new FormGroup({
      firstName: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('^(?! +$)[A-Za-zăâîșțĂÂÎȘȚ ]+$'),
        Validators.maxLength(30),
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^(?! +$)[A-Za-zăâîșțĂÂÎȘȚ ]+$'),
        Validators.maxLength(10),
      ]),
      emailId: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9._]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,4}$/),
        Validators.email
      ]),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]),
      pdf: new FormControl(null, Validators.required)
    });
  }

  onImagePicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.uploadResumeForm.get('pdf').setValue(file);
    this.uploadResumeForm.updateValueAndValidity();
  }
  onSubmit() {
    console.log(this.uploadResumeForm.value);
    this.resumeService.uploadResume(
      this.uploadResumeForm.value.firstName,
      this.uploadResumeForm.value.lastName,
      this.uploadResumeForm.value.emailId,
      this.uploadResumeForm.value.phoneNumber,
      this.uploadResumeForm.value.pdf
    ).subscribe(res => {
      if (!res.error) {
        console.log(res);
        this.route.navigate(['thankyou']);
      }

    })
  }

  get addEmployeeFormControl() {
    return this.uploadResumeForm.controls;
  }

}
