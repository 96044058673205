<!-- header form start -->



<section class="form2 cid-rIyETEx1Xt" id="form2-x"
  style="background-image: url(../../assets/images/science11-602x401.jpg);">

  <!-- Block parameters controls (Blue "Gear" panel) -->

  <div class="container">
    <div class="mbr-row">
      <div class="form__wrap rounded__10 mbr-col-sm-12 mbr-col-md-6 mbr-col-lg-6">
        <div class="title__block">
          <h3 class="form__title mbr-bold mbr-fonts-style mbr-black display-5">Quick Enquiry</h3>


        </div>
        <div class="mbr-column mbr-flex mbr-jc-c">
          <!--Formbuilder Form-->
          <form class="mbr-form" [formGroup]="contactForm" #form="ngForm" (ngSubmit)="onSubmit()">
            <div class="dragArea mbr-row">

              <div class="mbr-col-lg-6 mbr-col-md-6 mbr-col-sm-12 field">
                <input type="text" formControlName="firstName" placeholder="First Name" required="required" class="field-input display-4" value="">
                <div class="errorMessage">
                  <p *ngIf="(contactForm.controls.firstName.touched) && contactForm.controls.firstName.errors?.required"
                  class="text-danger">
                  Name is required.
                </p>
                <p *ngIf="contactForm.get('firstName').touched && (contactForm.get('firstName').errors?.pattern || contactForm.get('firstName').errors?.minlength || contactForm.get('firstName').errors?.maxlength)"
                  class="text-danger">This is invalid name!</p>
                </div>
              </div>

              <div class="mbr-col-lg-6 mbr-col-md-6 mbr-col-sm-12 field">
                <input type="text" formControlName="lastName" placeholder="Last Name" data-form-field="Last Name" required="required"
                  class="field-input display-4" value="">
                  <div class="errorMessage">
                    <p *ngIf="(contactForm.controls.lastName.touched) && contactForm.controls.lastName.errors?.required"
                    class="text-danger">
                    Last name is required.
                  </p>
                  <p *ngIf="contactForm.get('lastName').touched && (contactForm.get('lastName').errors?.pattern || contactForm.get('lastName').errors?.minlength || contactForm.get('lastName').errors?.maxlength)"
                    class="text-danger">This is invalid name!</p>
                  </div>
              </div>

              <div class="mbr-col-lg-12 mbr-col-md-12 mbr-col-sm-12 field">
                <input type="email" formControlName="emailId" placeholder="Email" data-form-field="E-mail" required="required"
                  class="field-input display-4" value="">
                  <div class="errorMessage">
                    <p *ngIf="(contactForm.controls.emailId.touched) && contactForm.controls.emailId.errors?.required"
                    class="text-danger">
                    Email Id is required.
                  </p>
                  <p *ngIf="contactForm.get('emailId').touched && (contactForm.get('emailId').errors?.pattern || contactForm.get('emailId').errors?.minlength || contactForm.get('emailId').errors?.maxlength)"
                    class="text-danger">This is invalid Email Id!</p>
                  </div>
              </div>
              <div class="mbr-col-lg-12 mbr-col-md-12 mbr-col-sm-12 field">
                <input type="tel" formControlName="phoneNumber" placeholder="9090909090" required="required" class="field-input display-4" value="">
                <div class="errorMessage">
                  <p *ngIf="(contactForm.controls.phoneNumber.touched) && contactForm.controls.phoneNumber.errors?.required"
                  class="text-danger">
                  Phone number is required.
                </p>
                <p *ngIf="contactForm.get('phoneNumber').touched && (contactForm.get('phoneNumber').errors?.pattern || contactForm.get('phoneNumber').errors?.minlength || contactForm.get('phoneNumber').errors?.maxlength)"
                  class="text-danger">This is invalid Phone number!</p>
                </div>
              </div>

              <div class="mbr-col-lg-12 mbr-col-md-12 mbr-col-sm-12 field">
                <textarea placeholder="Message" formControlName="message" class="field-input display-4"></textarea>
                <div class="errorMessage">
                  <p *ngIf="(contactForm.controls.message.touched) && contactForm.controls.message.errors?.required"
                  class="text-danger">
                  Message is required.
                </p>
                <p *ngIf="contactForm.get('message').touched && (contactForm.get('message').errors?.minlength || contactForm.get('message').errors?.maxlength)"
                  class="text-danger">Please wirte atleast 1 to 2 lines!</p>
                </div>
              </div>
              <div class="mbr-col-lg-6 mbr-col-md-12 mbr-col-sm-12 field field__btn mbr-flex">
                <button type="submit" [disabled]="contactForm.invalid" class="btn btn-md btn-form btn-primary display-4">
                  Send Message
                </button>
              </div>
            </div>
          </form>
          <!--Formbuilder Form-->
        </div>
      </div>

      <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-6 mbr-flex mbr-align-center ">
        <div class="text__block align-left">
          <div class="title">
            <h3 class="mbr-fonts-style card__title mbr-bold mbr-black display-2">Join our course program
            </h3>
            <h3 class="mbr-fonts-style card__title mbr-bold mbr-black display-3" style="padding-top: 10px;">to solve our
              real world
              problems</h3>
            <!-- <div class="mbr-section-btn"><a class="btn-primary btn btn-lg display-4">
                Read More</a></div> -->
          </div>
        </div>
      </div>




    </div>
  </div>
</section>


<!-- header form ends -->





<!-- Tabs Starts -->

<section class="tab cid-rIyFEHHTuL" id="tab-1e">



  <div class="container-flu">
    <amp-selector role="tablist" layout="container" class="ampTabContainer mbr-m-auto mbr-col-md-12 mbr-col-lg-12">
      <div class="title__block mbr-col-sm-12 mbr-col-md-12 mbr-flex mbr-mb-1">
        <div class="item__icon rounded__10 mbr-align-center mbr-jc-c mbr-flex">
          <span class="mbr-iconfont align-center mbr-iconfont-btn fa-folder-open fa"><svg width="100%" height="100%"
              viewBox="0 0 2048 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
              <path
                d="M1943 952q0 31-31 66l-336 396q-43 51-120.5 86.5t-143.5 35.5h-1088q-34 0-60.5-13t-26.5-43q0-31 31-66l336-396q43-51 120.5-86.5t143.5-35.5h1088q34 0 60.5 13t26.5 43zm-343-344v160h-832q-94 0-197 47.5t-164 119.5l-337 396-5 6q0-4-.5-12.5t-.5-12.5v-960q0-92 66-158t158-66h320q92 0 158 66t66 158v32h544q92 0 158 66t66 158z">
              </path>
            </svg></span>
        </div>
        <h2 class="title mbr-flex mbr-fonts-style mbr-black mbr-bold mbr-align-center display-5">Internship
        </h2>
      </div>
      <div role="tab" class="tabButton first__child" selected="" option="a">
        <h4 class="card-title mbr-fonts-style mbr-semibold mbr-black display-6">AI</h4>
      </div>
      <div role="tabpanel" class="tabContent">

        <div class="mbr-row wrapper mbr-row-reverse">
          <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-6 image">

            <amp-img src="assets/image/AI.jpg" alt="image" height="550" width="825" layout="responsive"
              class="mobirise-loader">
              <div placeholder="" class="placeholder">
                <div class="mobirise-spinner">
                  <em></em>
                  <em></em>
                  <em></em>
                </div>
              </div>

            </amp-img>
          </div>

          <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-6 mbr-flex mbr-align-center ">
            <div class="text__block align-left">
              <div class="title">
                <h3 class="mbr-fonts-style card__title mbr-bold mbr-black display-2">Artificial
                  Intelligence(AI)</h3>
                <h3 class="mbr-fonts-style card__title mbr-bold mbr-black display-3" style="padding-top: 10px;">
                  Introduction to Deep Learning</h3>
              </div>

              <div class="content__wrapper mbr-section-subtitle">

                <div class="mbr-flex">
                  <ul>
                    <li>Deep Learning: A revolution in Artificial Intelligence </li>
                    <li>Limitations of Machine Learning</li>
                    <li> What is Deep Learning?</li>
                    <li>Advantage of Deep Learning over Machine learning</li>
                    <li>3 Reasons to go for Deep Learning</li>
                    <li>Real-Life use cases of Deep Learning</li>
                    <li>Review of Machine Learning: Regression, Classification, Clustering,
                      Reinforcement Learning, Underfitting and Overfitting, Optimization</li>
                  </ul>
                </div>
              </div>
              <div class="title">
                <h3 class="mbr-fonts-style card__title mbr-bold mbr-black display-3" style="padding-top: 10px;">
                  Understanding Neural Networks with TensorFlow</h3>
              </div>

              <div class="content__wrapper mbr-section-subtitle">

                <div class="mbr-flex">
                  <ul>
                    <li>How Deep Learning Works?</li>
                    <li>Activation Functions</li>
                    <li>Illustrate Perceptron</li>
                    <li>Training a Perceptron</li>
                    <li>Important Parameters of Perceptron</li>
                    <li>What is TensorFlow?</li>
                    <li>TensorFlow code-basics</li>
                    <li>Graph Visualization</li>
                    <li>Constants, Placeholders, Variables</li>
                    <li>Creating a Model</li>
                    <li>Step by Step - Use-Case Implementation</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div role="tab" class="tabButton" option="b">
        <h4 class="card-title mbr-fonts-style mbr-semibold mbr-black display-6">MI</h4>
      </div>
      <div role="tabpanel" class="tabContent">
        <div class="mbr-row wrapper mbr-row-reverse">
          <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-6 image">

            <amp-img src="assets/image/MI02.jpg" alt="image" height="550" width="825" layout="responsive"
              class="mobirise-loader">
              <div placeholder="" class="placeholder">
                <div class="mobirise-spinner">
                  <em></em>
                  <em></em>
                  <em></em>
                </div>
              </div>

            </amp-img>
          </div>

          <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-6 mbr-flex mbr-align-center ">
            <div class="text__block align-left">
              <div class="title">
                <h3 class="mbr-fonts-style card__title mbr-bold mbr-black display-2">Machine
                  Learning(ML)</h3>
                <h3 class="mbr-fonts-style card__title mbr-bold mbr-black display-3" style="padding-top: 10px;">Chapter
                  - 1</h3>
                <h3 class="mbr-fonts-style card__title mbr-bold mbr-black display-3" style="padding-top: 10px;">
                  Installation Of Python Software And Machine Learning
                  Libraries</h3>

              </div>

              <div class="content__wrapper mbr-section-subtitle">

                <div class="mbr-flex">
                  <ul>
                    <li>Installation of python 2.7 / 3</li>
                    <li>Basic Knowledge On Python </li>
                    <li>Installation of Scikit-Learn</li>
                    <li>Installation of Numpy</li>
                    <li>Installation of Pandas</li>
                    <li>Installation of Tensor Flow</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div role="tab" class="tabButton last-child" option="c">
        <h4 class="card-title mbr-fonts-style mbr-semibold mbr-black display-6">IOT</h4>
      </div>
      <div role="tabpanel" class="tabContent">
        <div class="mbr-row wrapper mbr-row-reverse">
          <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-6 image">

            <amp-img src="assets/image/IOT.jpg" alt="image" height="550" width="825" layout="responsive"
              class="mobirise-loader">
              <div placeholder="" class="placeholder">
                <div class="mobirise-spinner">
                  <em></em>
                  <em></em>
                  <em></em>
                </div>
              </div>

            </amp-img>
          </div>

          <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-6 mbr-flex mbr-align-center ">
            <div class="text__block align-left">
              <div class="title">
                <h3 class="mbr-fonts-style card__title mbr-bold mbr-black display-2">Internet of
                  Things (IoT)</h3>
                <h3 class="mbr-fonts-style card__title mbr-bold mbr-black display-3" style="padding-top: 10px;">Course
                  Curriculum:</h3>
              </div>

              <div class="content__wrapper mbr-section-subtitle">

                <div class="mbr-flex">
                  <p class="mbr-fonts-style card__text mbr-section-subtitle display-4">Chira It is
                    one the best IOT training Institute in Bangalore Vijayanagar. specialize in
                    training courses for real time IOT training for both beginners and
                    professional matches international standards.A unique combination of
                    industry and training, consists of inhouse R&D and production. Internet of
                    Things Training(IOT). The course has been designed for professional with
                    basic knowledge of electronic circuit design, microcontrollers and
                    programming languages. The course introduces you to advance concepts and
                    design methodologies to design IoT systems and developing IoT applications
                    programming languages and tools optimized for IoT domain. It also exposes
                    participants to communication technologies and legacy protocols as well as
                    newly developed IoT specific application and physical layer protocols. Our
                    Internet things course or training syllabus is best and covers all latest
                    industry requirement.

                  </p>
                </div>
              </div>
              <div class="title">
                <h3 class="mbr-fonts-style card__title mbr-bold mbr-black display-3" style="padding-top: 10px;">Internet
                  of Things Training Objective:</h3>
              </div>

              <div class="content__wrapper mbr-section-subtitle">

                <div class="mbr-flex">
                  <p class="mbr-fonts-style card__text mbr-section-subtitle display-4">The course
                    has been designed for professionals with basic knowledge of electronic
                    circuit design, Microcontrollers and programming languages. The course
                    introduces you to advance concepts and design methodologies to design IoT
                    systems and developing IoT applications programming languages and tools for
                    IoT domain. It also exposes participants to communication technologies and
                    protocols as well as recently developed IoT specific application and
                    physical layer protocols. The course covers python language.

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div role="tab" class="tabButton last-child" option="d">
        <h4 class="card-title mbr-fonts-style mbr-semibold mbr-black display-6">Python</h4>
      </div>
      <div role="tabpanel" class="tabContent">
        <div class="mbr-row wrapper mbr-row-reverse">
          <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-6 image">

            <amp-img src="assets/image/Python.jpg" alt="image" height="550" width="825" layout="responsive"
              class="mobirise-loader">
              <div placeholder="" class="placeholder">
                <div class="mobirise-spinner">
                  <em></em>
                  <em></em>
                  <em></em>
                </div>
              </div>

            </amp-img>
          </div>

          <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-6 mbr-flex mbr-align-center ">
            <div class="text__block align-left">
              <div class="title">
                <h3 class="mbr-fonts-style card__title mbr-bold mbr-black display-2">Python
                </h3>
                <h3 class="mbr-fonts-style card__title mbr-bold mbr-black display-3" style="padding-top: 10px;">1.
                  Introduction to Python</h3>
              </div>

              <div class="content__wrapper mbr-section-subtitle">

                <div class="mbr-flex">
                  <ul>
                    <li>Installation of Python and Pycharm</li>
                    <li> Using the Python Interpreter IDLE</li>
                    <li> Salient Features of Python</li>
                    <li>Basic Syntax of Python</li>

                  </ul>
                </div>
              </div>
              <div class="title">
                <h3 class="mbr-fonts-style card__title mbr-bold mbr-black display-3" style="padding-top: 10px;">2. Using
                  Variables in Python</h3>
              </div>

              <div class="content__wrapper mbr-section-subtitle">

                <div class="mbr-flex">
                  <ul>
                    <li>Data Types and Variables</li>
                    <li>Numeric Data Types</li>
                    <li>String Data Type</li>
                    <li>List Data Type</li>
                    <li>Tuple Data Type</li>
                    <li>Dictionary Data Type</li>
                    <li>Assignment</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div role="tab" class="tabButton last-child" option="e">
        <h4 class="card-title mbr-fonts-style mbr-semibold mbr-black display-6">Embedded System</h4>
      </div>
      <div role="tabpanel" class="tabContent">
        <div class="mbr-row wrapper mbr-row-reverse">
          <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-6 image">

            <amp-img src="assets/image/Network.jpg" alt="image" height="550" width="825" layout="responsive"
              class="mobirise-loader">
              <div placeholder="" class="placeholder">
                <div class="mobirise-spinner">
                  <em></em>
                  <em></em>
                  <em></em>
                </div>
              </div>

            </amp-img>
          </div>

          <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-6 mbr-flex mbr-align-center ">
            <div class="text__block align-left">
              <div class="title">
                <h3 class="mbr-fonts-style card__title mbr-bold mbr-black display-2">Embedded System
                </h3>
                <h3 class="mbr-fonts-style card__title mbr-bold mbr-black display-3" style="padding-top: 10px;">
                  Introduction To Embedded Systems:</h3>
              </div>

              <div class="content__wrapper mbr-section-subtitle">

                <div class="mbr-flex">
                  <ul>
                    <li>Basics of Embedded System Design</li>
                    <li> Design methodology and various modules in the system</li>
                    <li> Real-Time Embedded Systems</li>
                    <li>Embedded System design challenges,embedded controllers etc.</li>
                    <li>Development environment</li>
                    <li> Current market trends & Applications,job opportunities</li>
                  </ul>
                </div>
              </div>
              <div class="title">
                <h3 class="mbr-fonts-style card__title mbr-bold mbr-black display-3" style="padding-top: 10px;">Advanced
                  C Programming:</h3>
              </div>

              <div class="content__wrapper mbr-section-subtitle">

                <div class="mbr-flex">
                  <ul>
                    <li>Stacks - Concepts, issues like over flow and under flow and uses.</li>
                    <li>Queues – Concepts, types – ordinary, circular, double ended, priority.
                      Issues and uses.</li>
                    <li>Linked list – Concepts, types – linear, circular, doubly, circular
                      doubly and uses.</li>
                    <li>Trees – Concepts, types – trees, binary trees, binary search tree and
                      uses.</li>
                    <li>Searching – binary, linear , linear search for ordered table,</li>
                    <li>Sorting – bubble, selection, insertion, simple merge, modified simple
                      merge, merge sort, quick sort, and tree sort.</li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div role="tab" class="tabButton last-child" option="e">
        <h4 class="card-title mbr-fonts-style mbr-semibold mbr-black display-6">Robotics</h4>
      </div>
      <div role="tabpanel" class="tabContent">
        <div class="mbr-row wrapper mbr-row-reverse">
          <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-6 image">

            <amp-img src="assets/images/about.jpg" alt="image" height="550" width="825" layout="responsive"
              class="mobirise-loader">
              <div placeholder="" class="placeholder">
                <div class="mobirise-spinner">
                  <em></em>
                  <em></em>
                  <em></em>
                </div>
              </div>

            </amp-img>
          </div>

          <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-6 mbr-flex mbr-align-center ">
            <div class="text__block align-left">
              <div class="title">
                <h3 class="mbr-fonts-style card__title mbr-bold mbr-black display-2">About Us</h3>
                <span class="divider"></span>
              </div>
              <p class="mbr-fonts-style card__text mbr-section-subtitle display-4">Lorem ipsum dolor
                sit amet,
                consectetur adipisicing elit. Eveniet recusandae ipsum ipsam quam, doloribus
                perferendis
                accusamus at voluptate nostrum explicabo!</p>
              <div class="content__wrapper mbr-section-subtitle">

                <div class="mbr-flex">
                  <p class="mbr-fonts-style item__service display-4">Lorem ipsum dolor sit amet,
                    consectetur adipisicing elit. Eveniet recusandae ipsum ipsam quam, doloribus
                    perferendis accusamus at voluptate nostrum explicabo!</p>
                </div>
                <div class="mbr-flex">
                  <p class="mbr-fonts-style item__service display-4">Lorem ipsum dolor sit amet,
                    consectetur adipisicing elit. Eveniet recusandae ipsum ipsam quam, doloribus
                    perferendis accusamus at voluptate nostrum explicabo!</p>
                </div>
              </div>
              <div class="blockquote mbr-medium">
                <div class="mbr-flex">
                  <p class="mbr-fonts-style quoter__text mbr-text display-4">Lorem ipsum dolor sit
                    amet,
                    consectetur adipisicing elit. Ratione, in.</p>
                </div>
              </div>

            </div>
          </div>
        </div>
        <h2 class="title__tab mbr-fonts-style mbr-black mbr-semibold display-6">Robotics</h2>
        <p class="mbr-text mbr-fonts-style display-4">Lorem ipsum dolor sit amet, consectetur adipisicing
          elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </div>



      <div role="tab" class="tabButton last-child" option="e">
        <h4 class="card-title mbr-fonts-style mbr-semibold mbr-black display-6">VISI</h4>
      </div>
      <div role="tabpanel" class="tabContent">
        <h2 class="title__tab mbr-fonts-style mbr-black mbr-semibold display-6">VISI</h2>
        <p class="mbr-text mbr-fonts-style display-4">Lorem ipsum dolor sit amet, consectetur adipisicing
          elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </div>



      <div role="tab" class="tabButton last-child" option="e">
        <h4 class="card-title mbr-fonts-style mbr-semibold mbr-black display-6">Big Data</h4>
      </div>
      <div role="tabpanel" class="tabContent ">
        <h2 class="title__tab mbr-fonts-style mbr-black mbr-semibold display-6">Big Data</h2>
        <p class="mbr-text mbr-fonts-style display-4">Lorem ipsum dolor sit amet, consectetur adipisicing
          elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </div>



      <div role="tab" class="tabButton last-child" option="e">
        <h4 class="card-title mbr-fonts-style mbr-semibold mbr-black display-6">Cloud Computing</h4>
      </div>
      <div role="tabpanel" class="tabContent">
        <h2 class="title__tab mbr-fonts-style mbr-black mbr-semibold display-6">Cloud Computing</h2>
        <p class="mbr-text mbr-fonts-style display-4">Lorem ipsum dolor sit amet, consectetur adipisicing
          elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </div>



      <div role="tab" class="tabButton last-child" option="e">
        <h4 class="card-title mbr-fonts-style mbr-semibold mbr-black display-6">R Language</h4>
      </div>
      <div role="tabpanel" class="tabContent">
        <h2 class="title__tab mbr-fonts-style mbr-black mbr-semibold display-6">R Language</h2>
        <p class="mbr-text mbr-fonts-style display-4">Lorem ipsum dolor sit amet, consectetur adipisicing
          elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </div>
    </amp-selector>

  </div>
</section>


<!-- Tabs Ends -->



<!-- hardware Projects -->
<section class="title1 cid-rIyFX5rmcz" style="padding-top: 10px; padding-bottom: 10px;" id="title1-1m">



  <div class="container">
    <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-12 mbr-px-0 align-center">

      <div class="title__block align-left align-center">
        <h3 class="mbr-bold title mbr-fonts-style mbr-black display-2">Hardware Projects</h3>
        <span class="divider"></span>

      </div>
    </div>
  </div>
</section>


<section class="features2 cid-rIyFuIqGbv" id="features2-15">



  <div class="container">

    <div class="mbr-row">
      <div class="mbr-col-sm-12 card mbr-col-lg-4 mbr-col-md-6">
        <div class="inner-item rounded__10">
          <div class="card-img">

            <div class="image item1">
              <div class="mbr-overlay rounded__10"></div>
              <amp-img src="assets/images/ect22.jpg" alt="image" height="200" width="300" layout="responsive"
                class="mobirise-loader rounded__10">
                <div placeholder="" class="placeholder">
                  <div class="mobirise-spinner">
                    <em></em>
                    <em></em>
                    <em></em>
                  </div>
                </div>

              </amp-img>

            </div>
            <div class="card-text align-center">
              <div class="card-text-box">

                <h3 class="mbr-fonts-style card__title mbr-semibold mbr-black display-6">
                  Embedded Projects ECE</h3>

                <span class="divider"></span>
                <p class="mbr-fonts-style mbr-regular card__text display-4">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="mbr-col-sm-12 card mbr-col-lg-4 mbr-col-md-6">
        <div class="inner-item rounded__10">
          <div class="card-img">
            <div class="image item2">
              <div class="mbr-overlay rounded__10"></div>
              <amp-img src="assets/images/ect7.jpg" alt="image" height="200" width="300" layout="responsive"
                class="mobirise-loader rounded__10">
                <div placeholder="" class="placeholder">
                  <div class="mobirise-spinner">
                    <em></em>
                    <em></em>
                    <em></em>
                  </div>
                </div>

              </amp-img>

            </div>
            <div class="card-text align-center">
              <div class="card-text-box">

                <h3 class="mbr-fonts-style card__title mbr-semibold mbr-black display-6">VLSI
                  Projects</h3>

                <span class="divider"></span>
                <p class="mbr-fonts-style mbr-regular card__text display-4">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="mbr-col-sm-12 card mbr-col-lg-4 mbr-col-md-6">
        <div class="inner-item rounded__10">
          <div class="card-img">
            <div class="image item3">
              <div class="mbr-overlay rounded__10"></div>
              <amp-img src="assets/image/Drone01.jpg" alt="image" height="200" width="300" layout="responsive"
                class="mobirise-loader rounded__10">
                <div placeholder="" class="placeholder">
                  <div class="mobirise-spinner">
                    <em></em>
                    <em></em>
                    <em></em>
                  </div>
                </div>

              </amp-img>

            </div>
            <div class="card-text align-center">
              <div class="card-text-box">

                <h3 class="mbr-fonts-style card__title mbr-semibold mbr-black display-6">Quad Copter
                </h3>

                <span class="divider"></span>
                <p class="mbr-fonts-style mbr-regular card__text display-4">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>




<!-- Progress --------------- -->

<section class="title1 cid-rIyFX5rmcz" style="background-color: #f0f3fc; padding-top: 20px; padding-bottom: 10px;"
  id="title1-1m">
  <div class="container">
    <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-12 mbr-px-0 align-center">
      <div class="mbr-flex mbr-align-center service__icon mbr-jc-c">
        <!-- <div class="item__icon rounded__10 mbr-align-center mbr-jc-c mbr-flex">
          <span class="mbr-iconfont align-center mbr-iconfont-btn fa-suitcase fa"><svg width="100%" height="100%"
              viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
              <path
                d="M640 384h512v-128h-512v128zm-352 0v1280h-64q-92 0-158-66t-66-158v-832q0-92 66-158t158-66h64zm1120 0v1280h-1024v-1280h128v-160q0-40 28-68t68-28h576q40 0 68 28t28 68v160h128zm384 224v832q0 92-66 158t-158 66h-64v-1280h64q92 0 158 66t66 158z">
              </path>
            </svg></span>
        </div> -->
      </div>
      <div class="title__block align-left align-center">
        <h3 class="mbr-bold title mbr-fonts-style mbr-black display-2">Why Choose Us</h3>
        <span class="divider"></span>
      </div>
    </div>
  </div>
</section>

<!-- 

<section class="progress cid-rIQ5bi12DB" style="background-color: #f0f3fc; padding-bottom: 10px;" id="progress-f">



  <div class="mbr-overlay"></div>
  <div class="container">

    <div class="mbr-row mbr-jc-c">
      <div class="progress_elements mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-12">

        <div class="progress1 progressbar mbr-pt-1">
          <div class="title-progressbar-wrap mbr-flex mbr-jc-sb">
            <p class="progressbar-title mbr-fonts-style mbr-semibold display-4">
              BIOTECHNOLOGY</p>
            <p class="progress_value mbr-fonts-style mbr-semibold display-4"></p>
          </div>
          <div class="progressbar-wrap">
            <div class="progress"></div>
          </div>
        </div>

        <div class="progress2 progressbar">
          <div class="title-progressbar-wrap mbr-flex mbr-jc-sb">
            <p class="progressbar-title mbr-fonts-style mbr-semibold display-4">MICROBIOLOGY</p>
            <p class="progress_value mbr-fonts-style mbr-semibold display-4"></p>
          </div>
          <div class="progressbar-wrap">
            <div class="progress"></div>
          </div>
        </div>

        <div class="progress3 progressbar last-child">
          <div class="title-progressbar-wrap mbr-flex mbr-jc-sb">
            <p class="progressbar-title mbr-fonts-style mbr-semibold display-4">
              MEDICAL RESEARCH</p>
            <p class="progress_value mbr-fonts-style mbr-semibold display-4"></p>
          </div>
          <div class="progressbar-wrap">
            <div class="progress"></div>
          </div>
        </div>






      </div>
    </div>
  </div>
</section> -->



<!-- What we are -->




<section class="features8 cid-rIQ9glm6RI" id="features8-m" style="padding-top: 10px;padding-bottom: 20px;">



  <div class="container">
    <div class="mbr-row mbr-align-center">

      <div class="mbr-col-sm-12 mbr-col-md-6 mbr-col-lg-4">
        <div class="inner-item rounded__10 mbr-flex">
          <div class="mbr-overlay rounded__10"></div>
          <amp-img src="assets/images/science11-602x401.jpg" alt="image" height="259" width="388.8229426433915"
            layout="responsive" class="mobirise-loader">
            <div placeholder="" class="placeholder">
              <div class="mobirise-spinner">
                <em></em>
                <em></em>
                <em></em>
              </div>
            </div>

          </amp-img>
          <div class="text__box mbr-flex mbr-column">
            <div class="service__icon item1 rounded__10 mbr-flex mbr-jc-c mbr-align-center">
              <span class="mbr-iconfont mbr-iconfont-btn align-center fa-bar-chart-o fa"><svg width="30" height="30"
                  viewBox="0 0 2048 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                  <path
                    d="M640 896v512h-256v-512h256zm384-512v1024h-256v-1024h256zm1024 1152v128h-2048v-1536h128v1408h1920zm-640-896v768h-256v-768h256zm384-384v1152h-256v-1152h256z">
                  </path>
                </svg></span>
            </div>
            <div class="card-text">
              <h3 class="mbr-fonts-style card__title mbr-semibold mbr-black display-5">Real world
                Projects from Industry Expert</h3>
              <p class="mbr-fonts-style mbr-regular card__text mbr-mt-1 display-4">With real world
                projects designed with digital technology or images to actively engage and
                creatively built in partnership with top tier companies. Master the tech skills that
                companies require</p>
            </div>
          </div>
        </div>
        <div class="inner-item rounded__10 mbr-flex">
          <div class="mbr-overlay rounded__10"></div>
          <amp-img src="assets/image/IT-services02.jpg" alt="image" height="259" width="388.8229426433915"
            layout="responsive" class="mobirise-loader">
            <div placeholder="" class="placeholder">
              <div class="mobirise-spinner">
                <em></em>
                <em></em>
                <em></em>
              </div>
            </div>

          </amp-img>
          <div class="text__box mbr-flex mbr-column">
            <div class="service__icon item3 rounded__10 mbr-flex mbr-jc-c mbr-align-center">
              <span class="mbr-iconfont mbr-iconfont-btn align-center fa-heartbeat fa"><svg width="30" height="30"
                  viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                  <path
                    d="M1280 1024h305q-5 6-10 10.5t-9 7.5l-3 4-623 600q-18 18-44 18t-44-18l-624-602q-5-2-21-20h369q22 0 39.5-13.5t22.5-34.5l70-281 190 667q6 20 23 33t39 13q21 0 38-13t23-33l146-485 56 112q18 35 57 35zm512-428q0 145-103 300h-369l-111-221q-8-17-25.5-27t-36.5-8q-45 5-56 46l-129 430-196-686q-6-20-23.5-33t-39.5-13-39 13.5-22 34.5l-116 464h-423q-103-155-103-300 0-220 127-344t351-124q62 0 126.5 21.5t120 58 95.5 68.5 76 68q36-36 76-68t95.5-68.5 120-58 126.5-21.5q224 0 351 124t127 344z">
                  </path>
                </svg></span>
            </div>
            <div class="card-text">
              <h3 class="mbr-fonts-style card__title mbr-semibold mbr-black display-5">1-on-1
                technical mentor</h3>
              <p class="mbr-fonts-style mbr-regular card__text mbr-mt-1 display-4">Mentor who has a
                vast domain expertise will guide you in training, focused on solving your queries,
                motivating you and leading you in a right career path.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-4 mbr-flex text__block align-center">
        <div class="title__block">

          <!-- <h3 class="mbr-section-title mbr-bold mbr-fonts-style mbr-black display-2">
                            <span style="font-weight: normal;">LABORATORY PRIORITY</span> SERVICES DELIVERED
                        </h3> -->
          <!-- <span class="divider"></span> -->
          <h4 class="mbr-section-subtitle mbr-fonts-style mbr-bold display-4">Commitment to training helps
            our students emerge as experts by giving them the resources to stay current in their field
            while arming them with the skills they need to continue down their chosen career path.</h4>


        </div>
      </div>

      <div class="mbr-col-sm-12 mbr-col-md-6 mbr-col-lg-4">
        <div class="inner-item rounded__10 mbr-flex push">
          <div class="mbr-overlay rounded__10"></div>
          <amp-img src="assets/images/science10-602x401.jpg" alt="image" height="259" width="388.8229426433915"
            layout="responsive" class="mobirise-loader">
            <div placeholder="" class="placeholder">
              <div class="mobirise-spinner">
                <em></em>
                <em></em>
                <em></em>
              </div>
            </div>

          </amp-img>
          <div class="text__box mbr-flex mbr-column">
            <div class="service__icon item2 rounded__10 mbr-flex mbr-jc-c mbr-align-center">
              <span class="mbr-iconfont mbr-iconfont-btn align-center fa-stethoscope fa"><svg width="30" height="30"
                  viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                  <path
                    d="M1472 704q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128 0q0 62-35.5 111t-92.5 70v395q0 159-131.5 271.5t-316.5 112.5-316.5-112.5-131.5-271.5v-132q-164-20-274-128t-110-252v-512q0-26 19-45t45-19q6 0 16 2 17-30 47-48t65-18q53 0 90.5 37.5t37.5 90.5-37.5 90.5-90.5 37.5q-33 0-64-18v402q0 106 94 181t226 75 226-75 94-181v-402q-31 18-64 18-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5q35 0 65 18t47 48q10-2 16-2 26 0 45 19t19 45v512q0 144-110 252t-274 128v132q0 106 94 181t226 75 226-75 94-181v-395q-57-21-92.5-70t-35.5-111q0-80 56-136t136-56 136 56 56 136z">
                  </path>
                </svg></span>
            </div>
            <div class="card-text">
              <h3 class="mbr-fonts-style card__title mbr-semibold mbr-black display-5">Get at your own
                pace
              </h3>
              <p class="mbr-fonts-style mbr-regular card__text mbr-mt-1 display-4">A tailor made
                customized training program to fit your busy life schedule without disturbing your
                personal commitment and make you reach the career goals.</p>
            </div>
          </div>
        </div>
        <div class="inner-item rounded__10 mbr-flex">
          <div class="mbr-overlay rounded__10"></div>
          <amp-img src="assets/image/banner02.jpg" alt="image" height="259" width="388.8229426433915"
            layout="responsive" class="mobirise-loader">
            <div placeholder="" class="placeholder">
              <div class="mobirise-spinner">
                <em></em>
                <em></em>
                <em></em>
              </div>
            </div>

          </amp-img>

          <div class="text__box mbr-flex mbr-column">
            <div class="service__icon item4 rounded__10 mbr-flex mbr-jc-c mbr-align-center">
              <span class="mbr-iconfont mbr-iconfont-btn align-center fa-edit fa"><svg width="30" height="30"
                  viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                  <path
                    d="M888 1184l116-116-152-152-116 116v56h96v96h56zm440-720q-16-16-33 1l-350 350q-17 17-1 33t33-1l350-350q17-17 1-33zm80 594v190q0 119-84.5 203.5t-203.5 84.5h-832q-119 0-203.5-84.5t-84.5-203.5v-832q0-119 84.5-203.5t203.5-84.5h832q63 0 117 25 15 7 18 23 3 17-9 29l-49 49q-14 14-32 8-23-6-45-6h-832q-66 0-113 47t-47 113v832q0 66 47 113t113 47h832q66 0 113-47t47-113v-126q0-13 9-22l64-64q15-15 35-7t20 29zm-96-738l288 288-672 672h-288v-288zm444 132l-92 92-288-288 92-92q28-28 68-28t68 28l152 152q28 28 28 68t-28 68z">
                  </path>
                </svg></span>
            </div>
            <div class="card-text">
              <h3 class="mbr-fonts-style card__title mbr-semibold mbr-black display-5">Career services
              </h3>
              <p class="mbr-fonts-style mbr-regular card__text mbr-mt-1 display-4">Resume building
                service, instructions for interview preparation and online professional profile
                reviews to help you grow in your career.</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>

<!-- Testmonials -->



<section class="title2 cid-rIV79pmQnr" id="title2-g">



  <div class="container">
    <div class="mbr-col-sm-12 mbr-px-0 mbr-col-lg-12 mbr-col-md-12">
      <div class="mbr-col-sm-12 right mbr-flex">
        <div class="service__icon align-left">
          <span class="mbr-iconfont mbr-iconfont-btn mbr-flex mbr-align-center mbr-jc-c fa-comments-o fa"><svg
              width="40" height="40" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
              <path
                d="M704 384q-153 0-286 52t-211.5 141-78.5 191q0 82 53 158t149 132l97 56-35 84q34-20 62-39l44-31 53 10q78 14 153 14 153 0 286-52t211.5-141 78.5-191-78.5-191-211.5-141-286-52zm0-128q191 0 353.5 68.5t256.5 186.5 94 257-94 257-256.5 186.5-353.5 68.5q-86 0-176-16-124 88-278 128-36 9-86 16h-3q-11 0-20.5-8t-11.5-21q-1-3-1-6.5t.5-6.5 2-6l2.5-5 3.5-5.5 4-5 4.5-5 4-4.5q5-6 23-25t26-29.5 22.5-29 25-38.5 20.5-44q-124-72-195-177t-71-224q0-139 94-257t256.5-186.5 353.5-68.5zm822 1169q10 24 20.5 44t25 38.5 22.5 29 26 29.5 23 25q1 1 4 4.5t4.5 5 4 5 3.5 5.5l2.5 5 2 6 .5 6.5-1 6.5q-3 14-13 22t-22 7q-50-7-86-16-154-40-278-128-90 16-176 16-271 0-472-132 58 4 88 4 161 0 309-45t264-129q125-92 192-212t67-254q0-77-23-152 129 71 204 178t75 230q0 120-71 224.5t-195 176.5z">
              </path>
            </svg></span> </div>

        <div class="title__block mbr-text">
          <h3 class="title mbr-section-title mbr-bold mbr-fonts-style mbr-black display-2">What Our
            Student's Say</h3>


        </div>

      </div>
    </div>
  </div>

</section>

<section class="testimoni als2 slider slider-with-bullets cid-rIV4Fd3Amy" id="testimonials2-a">
  <amp-state id="selectedtestimonials2a">
    <script type="application/json">
      {
        "slide": 0
      }

    </script>
  </amp-state>


  <div class="mbr-overlay"></div>
  <div class="mbr-row">
    <div class="slider-box mbr-flex mbr-column mbr-jc-c mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-12">
      <amp-carousel class="carousel" layout="fixed-height" type="slides" loop="" height="300"
        on="slideChange:AMP.setState({selectedtestimonials2a: {slide :event.index}})" id="carouseltestimonials2a">
        <div class="mbr-flex mbr-align-center">
          <div class="mbr-row mbr-jc-c">
            <div class="mbr-col-sm-12 mbr-col-md-8 mbr-col-lg-10">
              <div class="card-box align-center">
                <div class="text__block mbr-flex mbr-column mbr-align-center">
                  <div class="mbr-text mbr-flex quotes__align mbr-jc-c">

                  </div>
                  <div class="stars mbr-text">
                    <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                        width="15" height="15" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor">
                        <path
                          d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                        </path>
                      </svg></span>
                    <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                        width="15" height="15" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor">
                        <path
                          d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                        </path>
                      </svg></span>
                    <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                        width="15" height="15" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor">
                        <path
                          d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                        </path>
                      </svg></span>
                    <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                        width="15" height="15" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor">
                        <path
                          d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                        </path>
                      </svg></span>
                    <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star-o fa"><svg
                        width="15" height="15" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor">
                        <path
                          d="M1201 1004l306-297-422-62-189-382-189 382-422 62 306 297-73 421 378-199 377 199zm527-357q0 22-26 48l-363 354 86 500q1 7 1 20 0 50-41 50-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                        </path>
                      </svg></span>
                  </div>
                  <p class="mbr-fonts-style mbr-white quote-text mbr-medium display-5">Lorem ipsum
                    dolor sit amet, consectetur adipisicing elit. Molestias similique, odio
                    quibusdam neque iure corporis vero quidem eius quis, illum, alias odit quo
                    nihil. Eveniet tenetur repudiandae, accusamus. Quia, eum?</p>
                  <p class="mbr-fonts-style mbr-text mbr-white author mbr-medium display-4">Anna
                    Brown</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mbr-flex mbr-align-center">
          <div class="mbr-row mbr-jc-c">
            <div class="mbr-col-sm-12 mbr-col-md-8 mbr-col-lg-10">
              <div class="card-box align-center">
                <div class="text__block mbr-flex mbr-column mbr-align-center">
                  <div class="mbr-text mbr-flex quotes__align mbr-jc-c">

                  </div>
                  <div class="stars mbr-text">
                    <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                        width="15" height="15" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor">
                        <path
                          d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                        </path>
                      </svg></span>
                    <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                        width="15" height="15" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor">
                        <path
                          d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                        </path>
                      </svg></span>
                    <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                        width="15" height="15" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor">
                        <path
                          d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                        </path>
                      </svg></span>
                    <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                        width="15" height="15" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor">
                        <path
                          d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                        </path>
                      </svg></span>
                    <span
                      class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star-half-empty fa"><svg
                        width="15" height="15" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor">
                        <path
                          d="M1250 957l257-250-356-52-66-10-30-60-159-322v963l59 31 318 168-60-355-12-66zm452-262l-363 354 86 500q5 33-6 51.5t-34 18.5q-17 0-40-12l-449-236-449 236q-23 12-40 12-23 0-34-18.5t-6-51.5l86-500-364-354q-32-32-23-59.5t54-34.5l502-73 225-455q20-41 49-41 28 0 49 41l225 455 502 73q45 7 54 34.5t-24 59.5z">
                        </path>
                      </svg></span>
                  </div>
                  <p class="mbr-fonts-style mbr-white quote-text mbr-medium display-5">Lorem ipsum
                    dolor sit amet, consectetur adipisicing elit. Numquam, repellat aliquam.
                    Suscipit ducimus sequi ullam illo quidem qui minus nulla, enim, ut excepturi
                    id itaque nemo quibusdam autem molestias pariatur?</p>
                  <p class="mbr-fonts-style mbr-text mbr-white author mbr-medium display-4">Jack
                    Black</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mbr-flex mbr-align-center">
          <div class="mbr-row mbr-jc-c">
            <div class="mbr-col-sm-12 mbr-col-md-8 mbr-col-lg-10">
              <div class="card-box align-center">
                <div class="text__block mbr-flex mbr-column mbr-align-center">
                  <div class="mbr-text mbr-flex quotes__align mbr-jc-c">

                  </div>
                  <div class="stars mbr-text">
                    <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                        width="15" height="15" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor">
                        <path
                          d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                        </path>
                      </svg></span>
                    <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                        width="15" height="15" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor">
                        <path
                          d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                        </path>
                      </svg></span>
                    <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                        width="15" height="15" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor">
                        <path
                          d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                        </path>
                      </svg></span>
                    <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                        width="15" height="15" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor">
                        <path
                          d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                        </path>
                      </svg></span>
                    <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                        width="15" height="15" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor">
                        <path
                          d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                        </path>
                      </svg></span>
                  </div>
                  <p class="mbr-fonts-style mbr-white quote-text mbr-medium display-5">Lorem ipsum
                    dolor sit amet, consectetur adipisicing elit. Tempore voluptates ut corporis
                    impedit architecto repudiandae doloremque odit. Magni est nam praesentium,
                    quibusdam ducimus, repudiandae consectetur totam minus, at doloremque hic.
                  </p>
                  <p class="mbr-fonts-style mbr-text mbr-white author mbr-medium display-4">John
                    Green</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mbr-flex mbr-align-center">
          <div class="mbr-row mbr-jc-c">
            <div class="mbr-col-sm-12 mbr-col-md-8 mbr-col-lg-10">
              <div class="card-box align-center">
                <div class="text__block mbr-flex mbr-column mbr-align-center">
                  <div class="mbr-text mbr-flex quotes__align mbr-jc-c">

                  </div>
                  <div class="stars mbr-text">
                    <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                        width="15" height="15" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor">
                        <path
                          d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                        </path>
                      </svg></span>
                    <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                        width="15" height="15" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor">
                        <path
                          d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                        </path>
                      </svg></span>
                    <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                        width="15" height="15" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor">
                        <path
                          d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                        </path>
                      </svg></span>
                    <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star fa"><svg
                        width="15" height="15" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor">
                        <path
                          d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                        </path>
                      </svg></span>
                    <span class="mbr-iconfont mbr-flex mbr-align-center mbr-jc-c mbr-iconfont-btn fa-star-o fa"><svg
                        width="15" height="15" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor">
                        <path
                          d="M1201 1004l306-297-422-62-189-382-189 382-422 62 306 297-73 421 378-199 377 199zm527-357q0 22-26 48l-363 354 86 500q1 7 1 20 0 50-41 50-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                        </path>
                      </svg></span>
                  </div>
                  <p class="mbr-fonts-style mbr-white quote-text mbr-medium display-5">Lorem ipsum
                    dolor sit amet, consectetur adipisicing elit. Molestias similique, odio
                    quibusdam neque iure corporis vero quidem eius quis, illum, alias odit quo
                    nihil. Eveniet tenetur repudiandae, accusamus. Quia, eum?</p>
                  <p class="mbr-fonts-style mbr-text mbr-white author mbr-medium display-4">Anna
                    Brown</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </amp-carousel>
      <!-- <div class="dots-wrapper">
        <p class="dots"><span role="button" tabindex="0" on="tap:carouseltestimonials2a.goToSlide(index=0)"
            [class]="selectedtestimonials2a.slide == '0' ? 'current' : ''" class="current"></span>
        </p>
        <p class="dots"><span role="button" tabindex="1" on="tap:carouseltestimonials2a.goToSlide(index=1)"
            [class]="selectedtestimonials2a.slide == '1' ? 'current' : ''"></span>
        </p>
        <p class="dots"><span role="button" tabindex="2" on="tap:carouseltestimonials2a.goToSlide(index=2)"
            [class]="selectedtestimonials2a.slide == '2' ? 'current' : ''"></span>
        </p>
        <p class="dots"><span role="button" tabindex="3" on="tap:carouseltestimonials2a.goToSlide(index=3)"
            [class]="selectedtestimonials2a.slide == '3' ? 'current' : ''"></span>
        </p>
      </div> -->
    </div>
  </div>
</section>



<!-- Latest Courses -->





<section class="title1 cid-rIV6G7Mt4O" id="title1-e">



  <div class="container">
    <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-12 mbr-px-0 align-center">

      <div class="title__block align-left align-center">
        <h3 class="mbr-bold title mbr-section-title mbr-fonts-style mbr-black display-2">Latest Courses</h3>


      </div>
    </div>
  </div>
</section>

<section class="features5 cid-rIV5D0JJV0" id="features5-c">



  <div class="container">
    <div class="mbr-row">
      <div class="mbr-col-sm-12 card mbr-col-lg-4 mbr-col-md-6">
        <div class="inner-item rounded__10">
          <div class="card-img">
            <div class="image">
              <div class="mbr-overlay"></div>
              <amp-img src="assets/image/JAVA.png" alt="image" height="270" width="405" layout="responsive"
                class="mobirise-loader">
                <div placeholder="" class="placeholder">
                  <div class="mobirise-spinner">
                    <em></em>
                    <em></em>
                    <em></em>
                  </div>
                </div>
                <a href="#"></a>
              </amp-img>
              <div class="post">
                <p class="mbr-fonts-style post__text display-4"><span class="mbri-calendar mbr-iconfont"><svg
                      version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32 32"
                      fill="currentColor">

                      <path
                        d="M7.333 2.667c-0.369 0-0.667 0.297-0.667 0.667v5.333c0 0.369 0.297 0.667 0.667 0.667s0.667-0.297 0.667-0.667v-5.333c0-0.369-0.297-0.667-0.667-0.667zM24.667 2.667c-0.369 0-0.667 0.297-0.667 0.667v5.333c0 0.369 0.297 0.667 0.667 0.667s0.667-0.297 0.667-0.667v-5.333c0-0.369-0.297-0.667-0.667-0.667zM2 5.333c-1.096 0-2 0.904-2 2v20c0 1.096 0.904 2 2 2h28c1.096 0 2-0.904 2-2v-20c0-1.096-0.904-2-2-2h-2.667c-0.446 0-0.667 0.33-0.667 0.667s0.216 0.667 0.667 0.667h2.667c0.381 0 0.667 0.286 0.667 0.667v20c0 0.381-0.286 0.667-0.667 0.667h-28c-0.381 0-0.667-0.286-0.667-0.667v-20c0-0.381 0.286-0.667 0.667-0.667h2.667c0.448 0 0.67-0.333 0.667-0.667s-0.231-0.667-0.667-0.667h-2.667zM10 5.333c-0.431 0-0.667 0.33-0.667 0.667s0.216 0.673 0.667 0.667h12c0.397 0 0.667-0.33 0.667-0.667s-0.199-0.667-0.667-0.667h-12zM4.667 13.333c-0.369 0-0.667 0.297-0.667 0.667s0.297 0.667 0.667 0.667h4c0.369 0 0.667-0.297 0.667-0.667s-0.297-0.667-0.667-0.667h-4zM14 13.333c-0.369 0-0.667 0.297-0.667 0.667s0.297 0.667 0.667 0.667h4c0.369 0 0.667-0.297 0.667-0.667s-0.297-0.667-0.667-0.667h-4zM23.333 13.333c-0.369 0-0.667 0.297-0.667 0.667s0.297 0.667 0.667 0.667h4c0.369 0 0.667-0.297 0.667-0.667s-0.297-0.667-0.667-0.667h-4zM4.667 20c-0.369 0-0.667 0.297-0.667 0.667s0.297 0.667 0.667 0.667h4c0.369 0 0.667-0.297 0.667-0.667s-0.297-0.667-0.667-0.667h-4zM14 20c-0.369 0-0.667 0.297-0.667 0.667s0.297 0.667 0.667 0.667h4c0.369 0 0.667-0.297 0.667-0.667s-0.297-0.667-0.667-0.667h-4zM23.333 20c-0.369 0-0.667 0.297-0.667 0.667s0.297 0.667 0.667 0.667h4c0.369 0 0.667-0.297 0.667-0.667s-0.297-0.667-0.667-0.667h-4z">
                      </path>
                    </svg></span>90 Days</p>
              </div>
            </div>
            <div class="card-text align-left">
              <div class="card-text-box">
                <h3 class="mbr-fonts-style card__title mbr-semibold mbr-black display-6">Java
                  Projects</h3>
                <p class="mbr-fonts-style mbr-regular card__text display-4">2019 IEEE JAVA
                  SOFTWARE PROJECTS FOR MTECH / BE IN CLOUD COMPUTING , BIGDATA, HADOOP
                  &amp; DATAMINING Chira IT, Bangalore offers Final year
                  engineering project for Computer Science &amp; Engineering students,
                  Final year engineering project for CSE.</p>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mbr-col-sm-12 card mbr-col-lg-4 mbr-col-md-6">
        <div class="inner-item rounded__10">
          <div class="card-img">
            <div class="image">
              <div class="mbr-overlay"></div>
              <amp-img src="assets/image/Android.jpg" alt="image" height="270" width="405" layout="responsive"
                class="mobirise-loader">
                <div placeholder="" class="placeholder">
                  <div class="mobirise-spinner">
                    <em></em>
                    <em></em>
                    <em></em>
                  </div>
                </div>
                <a href="#"></a>
              </amp-img>
              <div class="post">
                <p class="mbr-fonts-style post__text display-4"><span class="mbri-calendar mbr-iconfont"><svg
                      version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32 32"
                      fill="currentColor">

                      <path
                        d="M7.333 2.667c-0.369 0-0.667 0.297-0.667 0.667v5.333c0 0.369 0.297 0.667 0.667 0.667s0.667-0.297 0.667-0.667v-5.333c0-0.369-0.297-0.667-0.667-0.667zM24.667 2.667c-0.369 0-0.667 0.297-0.667 0.667v5.333c0 0.369 0.297 0.667 0.667 0.667s0.667-0.297 0.667-0.667v-5.333c0-0.369-0.297-0.667-0.667-0.667zM2 5.333c-1.096 0-2 0.904-2 2v20c0 1.096 0.904 2 2 2h28c1.096 0 2-0.904 2-2v-20c0-1.096-0.904-2-2-2h-2.667c-0.446 0-0.667 0.33-0.667 0.667s0.216 0.667 0.667 0.667h2.667c0.381 0 0.667 0.286 0.667 0.667v20c0 0.381-0.286 0.667-0.667 0.667h-28c-0.381 0-0.667-0.286-0.667-0.667v-20c0-0.381 0.286-0.667 0.667-0.667h2.667c0.448 0 0.67-0.333 0.667-0.667s-0.231-0.667-0.667-0.667h-2.667zM10 5.333c-0.431 0-0.667 0.33-0.667 0.667s0.216 0.673 0.667 0.667h12c0.397 0 0.667-0.33 0.667-0.667s-0.199-0.667-0.667-0.667h-12zM4.667 13.333c-0.369 0-0.667 0.297-0.667 0.667s0.297 0.667 0.667 0.667h4c0.369 0 0.667-0.297 0.667-0.667s-0.297-0.667-0.667-0.667h-4zM14 13.333c-0.369 0-0.667 0.297-0.667 0.667s0.297 0.667 0.667 0.667h4c0.369 0 0.667-0.297 0.667-0.667s-0.297-0.667-0.667-0.667h-4zM23.333 13.333c-0.369 0-0.667 0.297-0.667 0.667s0.297 0.667 0.667 0.667h4c0.369 0 0.667-0.297 0.667-0.667s-0.297-0.667-0.667-0.667h-4zM4.667 20c-0.369 0-0.667 0.297-0.667 0.667s0.297 0.667 0.667 0.667h4c0.369 0 0.667-0.297 0.667-0.667s-0.297-0.667-0.667-0.667h-4zM14 20c-0.369 0-0.667 0.297-0.667 0.667s0.297 0.667 0.667 0.667h4c0.369 0 0.667-0.297 0.667-0.667s-0.297-0.667-0.667-0.667h-4zM23.333 20c-0.369 0-0.667 0.297-0.667 0.667s0.297 0.667 0.667 0.667h4c0.369 0 0.667-0.297 0.667-0.667s-0.297-0.667-0.667-0.667h-4z">
                      </path>
                    </svg></span>Sept 13, 2019</p>
              </div>
            </div>
            <div class="card-text align-left">
              <div class="card-text-box">
                <h3 class="mbr-fonts-style card__title mbr-semibold mbr-black display-6">Android
                  Projects</h3>
                <p class="mbr-fonts-style mbr-regular card__text display-4">
                  2019 IEEE ANDROID
                  PROJECTS FOR MTECH / BE IN GPS, GSM, BLUETOOTH &amp; GPRS,
                  Embedded System Application, Database
                  Applications, Surveillance Applications, Cloud Computing, Medical
                  Applications, Image Processing Applications</p>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mbr-col-sm-12 card mbr-col-lg-4 mbr-col-md-6">
        <div class="inner-item rounded__10">
          <div class="card-img">
            <div class="image">
              <div class="mbr-overlay"></div>
              <amp-img src="assets/image/DotNet.jpg" alt="image" height="270" width="405" layout="responsive"
                class="mobirise-loader">
                <div placeholder="" class="placeholder">
                  <div class="mobirise-spinner">
                    <em></em>
                    <em></em>
                    <em></em>
                  </div>
                </div>
                <a href="#"></a>
              </amp-img>
              <div class="post">
                <p class="mbr-fonts-style post__text display-4"><span class="mbri-calendar mbr-iconfont"><svg
                      version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32 32"
                      fill="currentColor">

                      <path
                        d="M7.333 2.667c-0.369 0-0.667 0.297-0.667 0.667v5.333c0 0.369 0.297 0.667 0.667 0.667s0.667-0.297 0.667-0.667v-5.333c0-0.369-0.297-0.667-0.667-0.667zM24.667 2.667c-0.369 0-0.667 0.297-0.667 0.667v5.333c0 0.369 0.297 0.667 0.667 0.667s0.667-0.297 0.667-0.667v-5.333c0-0.369-0.297-0.667-0.667-0.667zM2 5.333c-1.096 0-2 0.904-2 2v20c0 1.096 0.904 2 2 2h28c1.096 0 2-0.904 2-2v-20c0-1.096-0.904-2-2-2h-2.667c-0.446 0-0.667 0.33-0.667 0.667s0.216 0.667 0.667 0.667h2.667c0.381 0 0.667 0.286 0.667 0.667v20c0 0.381-0.286 0.667-0.667 0.667h-28c-0.381 0-0.667-0.286-0.667-0.667v-20c0-0.381 0.286-0.667 0.667-0.667h2.667c0.448 0 0.67-0.333 0.667-0.667s-0.231-0.667-0.667-0.667h-2.667zM10 5.333c-0.431 0-0.667 0.33-0.667 0.667s0.216 0.673 0.667 0.667h12c0.397 0 0.667-0.33 0.667-0.667s-0.199-0.667-0.667-0.667h-12zM4.667 13.333c-0.369 0-0.667 0.297-0.667 0.667s0.297 0.667 0.667 0.667h4c0.369 0 0.667-0.297 0.667-0.667s-0.297-0.667-0.667-0.667h-4zM14 13.333c-0.369 0-0.667 0.297-0.667 0.667s0.297 0.667 0.667 0.667h4c0.369 0 0.667-0.297 0.667-0.667s-0.297-0.667-0.667-0.667h-4zM23.333 13.333c-0.369 0-0.667 0.297-0.667 0.667s0.297 0.667 0.667 0.667h4c0.369 0 0.667-0.297 0.667-0.667s-0.297-0.667-0.667-0.667h-4zM4.667 20c-0.369 0-0.667 0.297-0.667 0.667s0.297 0.667 0.667 0.667h4c0.369 0 0.667-0.297 0.667-0.667s-0.297-0.667-0.667-0.667h-4zM14 20c-0.369 0-0.667 0.297-0.667 0.667s0.297 0.667 0.667 0.667h4c0.369 0 0.667-0.297 0.667-0.667s-0.297-0.667-0.667-0.667h-4zM23.333 20c-0.369 0-0.667 0.297-0.667 0.667s0.297 0.667 0.667 0.667h4c0.369 0 0.667-0.297 0.667-0.667s-0.297-0.667-0.667-0.667h-4z">
                      </path>
                    </svg></span>Sept 29, 2019</p>
              </div>
            </div>
            <div class="card-text align-left">
              <div class="card-text-box">

                <h3 class="mbr-fonts-style card__title mbr-semibold mbr-black display-6">DotNet
                  Projects</h3>
                <p class="mbr-fonts-style mbr-regular card__text display-4">2019 IEEE
                  PROJECTS in DOTNET FOR MTECH / BE IN CLOUD COMPUTING , NETWORKING &amp;
                  DATAMINING Chira IT, Bangalore offers project training for Java
                  based IEEE 2018 / 2017 projects on Bigdata, Java based IEEE 2018 /
                  2017 / 2016 projects on cloud computing.</p>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>

<section class="footer1 cid-rIQ7UaoDAW" id="footer1-i">
  <div class="container">
    <div class="mbr-row footer-wrap mbr-jc-c">
      <!-- <div class="image-block align-left mbr-col-sm-12 mbr-col-lg-2 mbr-col-md-4">
                  <amp-img src="../assets/images/logo-200x200.png" layout="responsive" width="130" height="130"
                      alt="Mobirise" class="mobirise-loader">
                      <div placeholder="" class="placeholder">
                          <div class="mobirise-spinner">
                              <em></em>
                              <em></em>
                              <em></em>
                          </div>
                      </div>

                  </amp-img>
              </div> -->
      <div class="items-col mbr-col-sm-12 align-left mbr-col-lg-3 mbr-col-md-4">
        <h3 class="mbr-fonts-style mbr-white group-title mbr-bold display-6">SERVICES</h3>
        <div class="items mbr-white">
          <p class="item mbr-fonts-style display-4">
            AMC</p>
          <p class="item mbr-fonts-style display-4">
            Network Solution</p>
          <p class="item mbr-fonts-style display-4">
            Server Solution</p>
          <p class="item mbr-fonts-style display-4">
            Server Solution</p>
        </div>
      </div>
      <div class="items-col mbr-col-sm-12 align-left mbr-col-lg-2 mbr-col-md-4">
        <h3 class="mbr-fonts-style mbr-white group-title mbr-bold display-6"> </h3>
        <div class="items mbr-white">
          <p class="item mbr-fonts-style display-4">
            Rental</p>
          <p class="item mbr-fonts-style display-4">
            Desktop</p>
          <p class="item mbr-fonts-style display-4">
            Laptop</p>
          <p class="item mbr-fonts-style display-4">
            Printer</p>
          <p class="item mbr-fonts-style display-4">
            Server</p>
        </div>
      </div>
      <div class="items-col mbr-col-sm-12 align-left mbr-col-lg-3 mbr-col-md-4">
        <h3 class="mbr-fonts-style mbr-white group-title mbr-bold display-6">Area Served</h3>
        <div class="items mbr-white">
          <p class="item mbr-fonts-style display-4">
            Bangalore</p>
          <p class="item mbr-fonts-style display-4">
            Mysore
          </p>
          <p class="item mbr-fonts-style display-4">
            Manglore</p>
          <p class="item mbr-fonts-style display-4">
            Chennai</p>
          <p class="item mbr-fonts-style display-4">
            Hyderabad
          </p>
        </div>
      </div>
      <div class="mbr-white align-left mbr-col-sm-12  mbr-col-md-12 mbr-col-lg-4">
        <h3 class="mbr-fonts-style group-title mbr-bold display-6">
          Contacts Us</h3>
        <div class="items mbr-white">
          <p class="item mbr-fonts-style mbr-bold display-5">
            Address</p>
          <p class=" mbr-fonts-style mbr-bold mbr-black display-4">
            Chira information Technology
          </p>
          <p class=" mbr-fonts-style mbr-black display-4">
            # 2934 & 2935E, 2nd floor,"AreRanga Complex "
            Vijayanagar Club Road, Vijayanagar, Bangalore -560040.
            Above McDonald's & HDFC Bank , Near Vijayanagar Metro Station
        </div>
      </div>
    </div>
    <div class="contacts__bottom mbr-flex flex-wrap mbr-align-center">
      <div class="mbr-col-sm-12 mbr-flex mbr-align-center bottom__left mbr-col-md-6">
        <span class="mbr-iconfont mbr-iconfont-btn fa-phone fa"><svg width="20" height="20" viewBox="0 0 1792 1792"
            xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <path
              d="M1600 1240q0 27-10 70.5t-21 68.5q-21 50-122 106-94 51-186 51-27 0-53-3.5t-57.5-12.5-47-14.5-55.5-20.5-49-18q-98-35-175-83-127-79-264-216t-216-264q-48-77-83-175-3-9-18-49t-20.5-55.5-14.5-47-12.5-57.5-3.5-53q0-92 51-186 56-101 106-122 25-11 68.5-21t70.5-10q14 0 21 3 18 6 53 76 11 19 30 54t35 63.5 31 53.5q3 4 17.5 25t21.5 35.5 7 28.5q0 20-28.5 50t-62 55-62 53-28.5 46q0 9 5 22.5t8.5 20.5 14 24 11.5 19q76 137 174 235t235 174q2 1 19 11.5t24 14 20.5 8.5 22.5 5q18 0 46-28.5t53-62 55-62 50-28.5q14 0 28.5 7t35.5 21.5 25 17.5q25 15 53.5 31t63.5 35 54 30q70 35 76 53 3 7 3 21z">
            </path>
          </svg></span>
        <a href="#">
          <p class="mbr-fonts-style phone mbr-semibold mbr-white display-6">080 4166 0033</p>
        </a>
      </div>
      <div class="mbr-col-sm-12 mbr-flex mbr-jc-e bottom__right mbr-col-md-6">
        <!-- SOCIAL ICON -->
        <div class="menu-social-list">
          <a href="http://www.youtube.com/channel/UCt_tncVAetpK5JeM8L-8jyw" target="_blank">
            <span class="amp-iconfont fa-youtube-play fa"><svg width="15" height="15" viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                <path
                  d="M711 1128l484-250-484-253v503zm185-862q168 0 324.5 4.5t229.5 9.5l73 4q1 0 17 1.5t23 3 23.5 4.5 28.5 8 28 13 31 19.5 29 26.5q6 6 15.5 18.5t29 58.5 26.5 101q8 64 12.5 136.5t5.5 113.5v176q1 145-18 290-7 55-25 99.5t-32 61.5l-14 17q-14 15-29 26.5t-31 19-28 12.5-28.5 8-24 4.5-23 3-16.5 1.5q-251 19-627 19-207-2-359.5-6.5t-200.5-7.5l-49-4-36-4q-36-5-54.5-10t-51-21-56.5-41q-6-6-15.5-18.5t-29-58.5-26.5-101q-8-64-12.5-136.5t-5.5-113.5v-176q-1-145 18-290 7-55 25-99.5t32-61.5l14-17q14-15 29-26.5t31-19.5 28-13 28.5-8 23.5-4.5 23-3 17-1.5q251-18 627-18z">
                </path>
              </svg></span>
          </a><a href="https://www.facebook.com/pages/Mobirise/1616226671953247" target="_blank">
            <span class="amp-iconfont fa-facebook-f fa"><svg width="15" height="15" viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                <path
                  d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z">
                </path>
              </svg></span>
          </a><a href="https://www.facebook.com/pages/Mobirise/1616226671953247" target="_blank">
            <span class="amp-iconfont fa-linkedin fa"><svg width="15" height="15" viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                <path
                  d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z">
                </path>
              </svg></span>
          </a><a href="https://www.facebook.com/pages/Mobirise/1616226671953247" target="_blank">
            <span class="amp-iconfont fa-vimeo-square fa"><svg width="15" height="15" viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                <path
                  d="M1420 638q10-216-161-222-231-8-312 261 44-19 82-19 85 0 74 96-4 57-74 167t-105 110q-43 0-82-169-13-54-45-255-30-189-160-177-59 7-164 100l-81 72-81 72 52 67q76-52 87-52 57 0 107 179 15 55 45 164.5t45 164.5q68 179 164 179 157 0 383-294 220-283 226-444zm244-222v960q0 119-84.5 203.5t-203.5 84.5h-960q-119 0-203.5-84.5t-84.5-203.5v-960q0-119 84.5-203.5t203.5-84.5h960q119 0 203.5 84.5t84.5 203.5z">
                </path>
              </svg></span>
          </a></div>
        <!-- SOCIAL ICON END -->
      </div>
    </div>
    <div class="divider__foot"></div>
    <div class="footer-container container">
      <div class="copyright align-left">
        <p class="mbr-text mbr-fonts-style mbr-white display-4">© Copyright 2021 Chira Information
          Technology - All Rights
          Reserved</p>
      </div>
    </div>
  </div>
</section>
