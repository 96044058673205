import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from '../service/service.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  freeEnquiryForm: FormGroup;
  
  @ViewChild('closeSidebar') closeSidebar:ElementRef
  constructor(
    private resumeService:ServiceService,
    private route:Router
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.freeEnquiryForm = new FormGroup({
      firstName: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('^(?! +$)[A-Za-zăâîșțĂÂÎȘȚ ]+$'),
        Validators.maxLength(30),
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('^(?! +$)[A-Za-zăâîșțĂÂÎȘȚ ]+$'),
        Validators.maxLength(30),
      ]),
      emailId: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9._]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,4}$/),
        Validators.email
      ]),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]),
      about: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30)
      ]),
    });
  }

  onHome(){
    this.route.navigate(['home']);
  }
  
  onSolutionProduct(){
    console.log("hello");
    this.route.navigate(['/solutions/products']);
    // this.closeSidebar.nativeElement.click()
   
  }
  onSolutionServices(){
    this.route.navigate(['solutions/services']);
    this.closeSidebar.nativeElement.click()
  }
  onSolutionIT(){
    this.route.navigate(['solutions/it-solutions']);
    this.closeSidebar.nativeElement.click()
  }
  onAcademic(){
    this.route.navigate(['academic']);
    this.closeSidebar.nativeElement.click()
  }
  onRecruitmentProfessional(){
    this.route.navigate(['recruitment/professional-service']);
    this.closeSidebar.nativeElement.click()
  }
  onRecruitmentManaged(){
    this.route.navigate(['recruitment/managed-service']);
    this.closeSidebar.nativeElement.click()
  }
  onRecruitmentCorporate(){
    this.route.navigate(['recruitment/corporate-training']);
    this.closeSidebar.nativeElement.click()
  }

  onSubmit(){
    console.log(this.freeEnquiryForm.value);
    this.resumeService.freeEnquery(this.freeEnquiryForm.value).subscribe(res => {
      if (!res.error) {
      console.log(res);
        this.route.navigate(['thankyou']);
      }
      // alert(res.message)
      
    })
  }
  onServices(){
    this.route.navigate(['solutions/services']);
  }
  onProducts(){
    this.route.navigate(['solutions/products']);
  }
  onITService(){
    this.route.navigate(['solutions/it-solutions']);
  // }
  // onAcademic(){
  //   this.route.navigate(['academic']);
  }
  onRecruitment(){
    this.route.navigate(['recruitment/professional-service']);
  }
}
