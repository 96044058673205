<div class="container mobileview">
  <input type="checkbox" id="toggle" #closeSidebar/>
  <div class="">
    <img src="../../assets/chira_logo.png" style="width:60px;height:60px">
    <label class="button" for="toggle">
      <i class="fa fa-bars" aria-hidden="true"></i>
    </label>
  </div>
     
 
  
  <nav class="nav">
    <ul>
     <li class="nav-item">
      <a class="nav-link" (click)=" onHome()">Home</a>
     </li>
      <li class="nav-item dropdown">
        <a class="nav-link mbr-medium  link  dropdown-toggle text-primary display-4" aria-expanded="false">
          Solutions
        </a>
        <div class="dropdown-menu mbr-medium">
          <a class="dropdown-item text-primary display-4" (click)="onSolutionProduct()">
            Products
          </a>
          <a class="dropdown-item text-primary display-4" (click)=" onSolutionServices()">
            Services
          </a>
          <a class="dropdown-item text-primary display-4"  (click)="onSolutionIT()">
            IT Solutions
          </a>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link mbr-medium link   text-primary display-4" (click)="onAcademic()" aria-expanded="false">
          Academic
        </a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link mbr-medium  link  dropdown-toggle text-primary display-4" aria-expanded="false">
          Recruitment
        </a>
        <div class="dropdown-menu mbr-medium">
          <a class="dropdown-item text-primary display-4" (click)="onRecruitmentProfessional()">
            Professional Service 
          </a>
          <a class="dropdown-item text-primary display-4" (click)="onRecruitmentManaged()">
            Managed Services
          </a>
          <a class="dropdown-item text-primary display-4" (click)="onRecruitmentCorporate()">
            Corporate training Factory
          </a>
          <!-- <a class="dropdown-item text-primary display-4" routerLink="recruitment/recruitment-consultant">
            Recruitment Consultants
          </a>
          <a class="dropdown-item text-primary display-4" routerLink="recruitment/permanent-staffing">
            Permanent Staffing
          </a> -->
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link mbr-medium  link  text-primary display-4" aria-expanded="false">Innovations</a>
      </li>
    </ul>
    <div class="mbr-section-btn" style="text-align: center;"><a class="btn-primary btn btn-lg display-4" [routerLink]="['resume']"
      routerLinkActive="router-link-active">
      Send CV</a>
  </div>
  </nav>
</div>

<section class="menu1 menu horizontal-menu cid-rIPiaw09AX" id="menu1-0 laptopView">
  <!-- <div class="menu-wrapper"> -->
  <nav class="navbar navbar-dropdown navbar-expand-lg navbar-fixed-top">
    <div class="menu-container mbr-column mbr-jc-c container">
      <!-- SHOW LOGO -->
      <div class="mbr-flex menu__block mbr-jc-sb mbr-jc-c">
        <div class="navbar-brand">
          <div class="navbar-logo">
            <amp-img src="../../assets/chira_logo.png" layout="responsive" width="60" height="60" alt="Mobirise"
              class="mobirise-loader">
              <div placeholder="" class="placeholder">
                <div class="mobirise-spinner">
                  <em></em>
                  <em></em>
                  <em></em>
                </div>
              </div>
              <a routerLink="home"> </a>
            </amp-img>
            <h5 class="text-primary display-4">Chira IT Software Solutionss</h5>
          </div>
        </div>
        <!-- SHOW LOGO END -->
        <!-- COLLAPSED MENU -->
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <!-- NAVBAR ITEMS -->
          <ul class="navbar-nav nav-dropdown" data-app-modern-menu="true">
            <li class="nav-item">
              <a class="nav-link mbr-medium  link  text-primary display-4" (click)="onHome()"
                aria-expanded="false">Home</a>
            </li>
            <!-- <li class="nav-item">
                                  <a class="nav-link mbr-medium  link  text-primary display-4" href="solutions.html"
                                      aria-expanded="false"> Our Solutions </a>
                              </li> -->
            <li class="nav-item dropdown">
              <a class="nav-link mbr-medium  link  dropdown-toggle text-primary display-4" aria-expanded="false">
                Solutions
              </a>
              <div class="dropdown-menu mbr-medium">
                <a class="dropdown-item text-primary display-4" routerLink="solutions/products">
                  Products
                </a>
                <a class="dropdown-item text-primary display-4" routerLink="solutions/services">
                  Services
                </a>
                <a class="dropdown-item text-primary display-4" routerLink="solutions/it-solutions">
                  IT Solutions
                </a>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link mbr-medium link   text-primary display-4" routerLink="academic" aria-expanded="false">
                Academic
              </a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link mbr-medium  link  dropdown-toggle text-primary display-4" aria-expanded="false">
                Recruitment
              </a>
              <div class="dropdown-menu mbr-medium">
                <a class="dropdown-item text-primary display-4" routerLink="recruitment/professional-service">
                  Professional Service 
                </a>
                <a class="dropdown-item text-primary display-4" routerLink="recruitment/managed-service">
                  Managed Services
                </a>
                <a class="dropdown-item text-primary display-4" routerLink="recruitment/corporate-training">
                  Corporate training Factory
                </a>
                <!-- <a class="dropdown-item text-primary display-4" routerLink="recruitment/recruitment-consultant">
                  Recruitment Consultants
                </a>
                <a class="dropdown-item text-primary display-4" routerLink="recruitment/permanent-staffing">
                  Permanent Staffing
                </a> -->
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link mbr-medium  link  text-primary display-4" aria-expanded="false">Innovations</a>
            </li>
            <!-- <li class="nav-item"><a class="nav-link mbr-medium  link text-primary display-4"
                                      href="index.html#testimonials1-p" aria-expanded="false">Internship</a>
                                  </li> -->
          </ul>
          <div class="mbr-section-btn"><a class="btn-primary btn btn-lg display-4" [routerLink]="['resume']"
              routerLinkActive="router-link-active">
              Send CV</a>
          </div>
          <!-- NAVBAR ITEMS END -->
        </div>
        <!-- COLLAPSED MENU END -->
        <!-- <button on="tap:sidebar.toggle" class="ampstart-btn hamburger">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </button> -->
      </div>
    </div>
  </nav>
  <!-- AMP plug -->
  <!-- </div> -->
</section>
