import {
  Component,
  OnInit
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  Router
} from '@angular/router';
import {
  ServiceService
} from '../service/service.service';

@Component({
  selector: 'app-academic',
  templateUrl: './academic.component.html',
  styleUrls: ['./academic.component.css']
})
export class AcademicComponent implements OnInit {
  contactForm: FormGroup;
  constructor(
    private route: Router,
    private resumeService: ServiceService
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.contactForm = new FormGroup({
      firstName: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('^(?! +$)[A-Za-zăâîșțĂÂÎȘȚ ]+$'),
        Validators.maxLength(30),
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('^(?! +$)[A-Za-zăâîșțĂÂÎȘȚ ]+$'),
        Validators.maxLength(30),
      ]),
      emailId: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9._]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,4}$/),
        Validators.email
      ]),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]),
      message: new FormControl('', [
        Validators.required,
        Validators.minLength(15),
        Validators.maxLength(80)
      ]),
    });
  }

  onSubmit() {
    console.log(this.contactForm.value);
    this.resumeService.enqueryAcademic(
      this.contactForm.value
    ).subscribe(res => {
      if (!res.error) {
        console.log(res);
        this.route.navigate(['thankyou']);
      }

    })
  }

  get addEmployeeFormControl() {
    return this.contactForm.controls;
  }
}
