<div>
  <section class="title2 cid-rIUGR3uSrn" style="background-color: #fcfcfc;" id="contactUsForm">
    <div class="container">
      <div class="mbr-col-sm-12 mbr-px-0 mbr-col-lg-12 mbr-col-md-12">
        <div class="mbr-col-sm-12 right mbr-flex">
          <div class="service__icon align-left">
            <span class="mbr-iconfont mbr-iconfont-btn mbr-flex mbr-align-center mbr-jc-c fa-briefcase fa"><svg
                width="40" height="40" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                <path
                  d="M640 256h512v-128h-512v128zm1152 640v480q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-480h672v160q0 26 19 45t45 19h320q26 0 45-19t19-45v-160h672zm-768 0v128h-256v-128h256zm768-480v384h-1792v-384q0-66 47-113t113-47h352v-160q0-40 28-68t68-28h576q40 0 68 28t28 68v160h352q66 0 113 47t47 113z">
                </path>
              </svg></span> </div>

          <div class="title__block mbr-text">
            <h3 class="title mbr-section-title mbr-bold mbr-fonts-style mbr-black display-2">
              Send Your CV
            </h3>
            <span class="divider"></span>
            <!-- <h4 class="mbr-section-subtitle mbr-fonts-style display-4">Lorem ipsum dolor sit amet,
                      consectetur adipisicing elit. <br>Eos tempora quibusdam provident, iure facere, culpa
                      consequatur neque vel ipsum at.</h4> -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="cid-rIyETsYeLm" style="background-color: #ffffff;">
    <!-- Block parameters controls (Blue "Gear" panel) -->
    <div class="container">
      <div class="form__wrap rounded__10">
        <div class="mbr-column mbr-flex mbr-jc-c">
          <!--Formbuilder Form-->
          <form class="mbr-form" [formGroup]="uploadResumeForm" #form="ngForm" (ngSubmit)="onSubmit()">
            <div class="dragArea mbr-row">
              <div class="mbr-col-lg-4 mbr-col-md-6 mbr-col-sm-12 field ">
                <label class="form-control-label mbr-fonts-style display-4">First name*</label>
                <!-- <input type="hidden" value="Name" data-form-field=""> -->
                <span class="mbr-iconfont fa-user fa">
                  <svg width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1536 1399q0 109-62.5 187t-150.5 78h-854q-88 0-150.5-78t-62.5-187q0-85 8.5-160.5t31.5-152 58.5-131 94-89 134.5-34.5q131 128 313 128t313-128q76 0 134.5 34.5t94 89 58.5 131 31.5 152 8.5 160.5zm-256-887q0 159-112.5 271.5t-271.5 112.5-271.5-112.5-112.5-271.5 112.5-271.5 271.5-112.5 271.5 112.5 112.5 271.5z">
                    </path>
                  </svg>
                </span>
                <div class="errorMessage">
                  <p *ngIf="(uploadResumeForm.controls.firstName.touched) && uploadResumeForm.controls.firstName.errors?.required"
                  class="text-danger">
                  First name  is required.
                </p>
                <p *ngIf="uploadResumeForm.get('firstName').touched && (uploadResumeForm.get('firstName').errors?.pattern || uploadResumeForm.get('firstName').errors?.minlength || uploadResumeForm.get('firstName').errors?.maxlength)"
                  class="text-danger">This is invalid name!</p>
                </div>
                <input type="text" formControlName="firstName" placeholder="Harshith" class="field-input display-4"
                  required="required" value="">
                  
              </div>
            
              <div class="mbr-col-lg-4 mbr-col-md-6 mbr-col-sm-12 field ">
                <label class="form-control-label mbr-fonts-style display-4">
                  Last name*</label>
                <span class="mbr-iconfont fa-user fa"><svg width="100%" height="100%" viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                    <path
                      d="M1536 1399q0 109-62.5 187t-150.5 78h-854q-88 0-150.5-78t-62.5-187q0-85 8.5-160.5t31.5-152 58.5-131 94-89 134.5-34.5q131 128 313 128t313-128q76 0 134.5 34.5t94 89 58.5 131 31.5 152 8.5 160.5zm-256-887q0 159-112.5 271.5t-271.5 112.5-271.5-112.5-112.5-271.5 112.5-271.5 271.5-112.5 271.5 112.5 112.5 271.5z">
                    </path>
                  </svg>
                </span>
                 <div class="errorMessage">
                  <p *ngIf="(uploadResumeForm.controls.lastName.touched) && uploadResumeForm.controls.lastName.errors?.required"
                  class="text-danger">
                  Last name  is required.
                </p>
                <p *ngIf="uploadResumeForm.get('lastName').touched && (uploadResumeForm.get('lastName').errors?.pattern || uploadResumeForm.get('lastName').errors?.minlength || uploadResumeForm.get('lastName').errors?.maxlength)"
                  class="text-danger">This is invalid name!</p>
                </div>
                <input type="text" formControlName="lastName" placeholder="Kumar" class="field-input display-4"
                  required="required" value="">
              </div>
              <div class="mbr-col-lg-4 mbr-col-md-6 mbr-col-sm-12 field">
                <label class="form-control-label mbr-fonts-style display-4">
                  Phone Number*
                </label>
                <span class="mbr-iconfont fa-phone fa">
                  <svg width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1600 1240q0 27-10 70.5t-21 68.5q-21 50-122 106-94 51-186 51-27 0-53-3.5t-57.5-12.5-47-14.5-55.5-20.5-49-18q-98-35-175-83-127-79-264-216t-216-264q-48-77-83-175-3-9-18-49t-20.5-55.5-14.5-47-12.5-57.5-3.5-53q0-92 51-186 56-101 106-122 25-11 68.5-21t70.5-10q14 0 21 3 18 6 53 76 11 19 30 54t35 63.5 31 53.5q3 4 17.5 25t21.5 35.5 7 28.5q0 20-28.5 50t-62 55-62 53-28.5 46q0 9 5 22.5t8.5 20.5 14 24 11.5 19q76 137 174 235t235 174q2 1 19 11.5t24 14 20.5 8.5 22.5 5q18 0 46-28.5t53-62 55-62 50-28.5q14 0 28.5 7t35.5 21.5 25 17.5q25 15 53.5 31t63.5 35 54 30q70 35 76 53 3 7 3 21z">
                    </path>
                  </svg>
                </span>
                <div class="errorMessage">
                  <p *ngIf="(uploadResumeForm.controls.phoneNumber.touched) && uploadResumeForm.controls.phoneNumber.errors?.required"
                  class="text-danger">
                  Phone number is required.
                </p>
                <p *ngIf="uploadResumeForm.get('phoneNumber').touched && (uploadResumeForm.get('phoneNumber').errors?.pattern || uploadResumeForm.get('phoneNumber').errors?.minlength || uploadResumeForm.get('phoneNumber').errors?.maxlength)"
                  class="text-danger">This is invalid Phone number!</p>
                </div>
                <input type="tel" formControlName="phoneNumber" placeholder="Full Number"
                    class="field-input display-4" value="">
              </div>

              <div class="mbr-col-lg-4 mbr-col-md-6 mbr-col-sm-12 field">
                <label class="form-control-label mbr-fonts-style display-4">Email Id*</label>
                <span class="mbr-iconfont fa-envelope fa">
                  <svg width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor">
                    <path
                      d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z">
                    </path>
                  </svg>
                </span>
                <div class="errorMessage">
                  <p *ngIf="(uploadResumeForm.controls.emailId.touched) && uploadResumeForm.controls.emailId.errors?.required"
                  class="text-danger">
                  Email Id is required.
                </p>
                <p *ngIf="uploadResumeForm.get('emailId').touched && (uploadResumeForm.get('emailId').errors?.pattern || uploadResumeForm.get('emailId').errors?.minlength || uploadResumeForm.get('emailId').errors?.maxlength)"
                  class="text-danger">This is invalid Email Id!</p>
                </div>
                <input type="email" formControlName="emailId" placeholder="name@company.com" data-form-field="E-mail"
                  class="field-input display-4" required="required" value="">
              </div>
              <div class="mbr-col-lg-4 mbr-col-md-6 mbr-col-sm-12 field">
                <label class="form-control-label mbr-fonts-style display-4">Upload Resume *</label>
                <span class="mbr-iconfont fa-file fa"><svg width="100%" height="100%" viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                    <path
                      d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z">
                    </path>
                  </svg></span>
                  
                <input type="file" #pdfFile (change)="onImagePicked($event)" accept=".pdf, .doc ,.docx"
                  class="field-input display-4" required="required" value="">
              </div>
              <div class="mbr-col-lg-4 mbr-col-md-6 mbr-col-sm-12 field field__btn mbr-flex">
                <button type="submit" [disabled]="uploadResumeForm.invalid" class="btn btn-sm btn-form btn-primary display-4">
                  Send
                </button></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>


  
  <section class="footer1 cid-rIQ7UaoDAW" id="footer1-i">
    <div class="container">
      <div class="mbr-row footer-wrap mbr-jc-c">
        <!-- <div class="image-block align-left mbr-col-sm-12 mbr-col-lg-2 mbr-col-md-4">
              <amp-img src="../assets/images/logo-200x200.png" layout="responsive" width="130" height="130"
                  alt="Mobirise" class="mobirise-loader">
                  <div placeholder="" class="placeholder">
                      <div class="mobirise-spinner">
                          <em></em>
                          <em></em>
                          <em></em>
                      </div>
                  </div>

              </amp-img>
          </div> -->
        <div class="items-col mbr-col-sm-12 align-left mbr-col-lg-3 mbr-col-md-4">
          <h3 class="mbr-fonts-style mbr-white group-title mbr-bold display-6">SERVICES</h3>
          <div class="items mbr-white">
            <p class="item mbr-fonts-style display-4">
              AMC</p>
            <p class="item mbr-fonts-style display-4">
              Network Solution</p>
            <p class="item mbr-fonts-style display-4">
              Server Solution</p>
            <p class="item mbr-fonts-style display-4">
              Server Solution</p>
          </div>
        </div>
        <div class="items-col mbr-col-sm-12 align-left mbr-col-lg-2 mbr-col-md-4">
          <h3 class="mbr-fonts-style mbr-white group-title mbr-bold display-6"> </h3>
          <div class="items mbr-white">
            <p class="item mbr-fonts-style display-4">
              Rental</p>
            <p class="item mbr-fonts-style display-4">
              Desktop</p>
            <p class="item mbr-fonts-style display-4">
              Laptop</p>
            <p class="item mbr-fonts-style display-4">
              Printer</p>
            <p class="item mbr-fonts-style display-4">
              Server</p>
          </div>
        </div>
        <div class="items-col mbr-col-sm-12 align-left mbr-col-lg-3 mbr-col-md-4">
          <h3 class="mbr-fonts-style mbr-white group-title mbr-bold display-6">Area Served</h3>
          <div class="items mbr-white">
            <p class="item mbr-fonts-style display-4">
              Bangalore</p>
            <p class="item mbr-fonts-style display-4">
              Mysore
            </p>
            <p class="item mbr-fonts-style display-4">
              Manglore</p>
            <p class="item mbr-fonts-style display-4">
              Chennai</p>
            <p class="item mbr-fonts-style display-4">
              Hyderabad
            </p>
          </div>
        </div>
        <div class="mbr-white align-left mbr-col-sm-12  mbr-col-md-12 mbr-col-lg-4">
          <h3 class="mbr-fonts-style group-title mbr-bold display-6">
            Contacts Us</h3>
          <div class="items mbr-white">
            <p class="item mbr-fonts-style mbr-bold display-5">
              Address</p>
            <p class=" mbr-fonts-style mbr-bold mbr-black display-4">
              Chira information Technology
            </p>
            <p class=" mbr-fonts-style mbr-black display-4">
              # 2934 & 2935E, 2nd floor,"AreRanga Complex "
              Vijayanagar Club Road, Vijayanagar, Bangalore -560040.
              Above McDonald's & HDFC Bank , Near Vijayanagar Metro Station
          </div>
        </div>
      </div>
      <div class="contacts__bottom mbr-flex flex-wrap mbr-align-center">
        <div class="mbr-col-sm-12 mbr-flex mbr-align-center bottom__left mbr-col-md-6">
          <span class="mbr-iconfont mbr-iconfont-btn fa-phone fa"><svg width="20" height="20"
              viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
              <path
                d="M1600 1240q0 27-10 70.5t-21 68.5q-21 50-122 106-94 51-186 51-27 0-53-3.5t-57.5-12.5-47-14.5-55.5-20.5-49-18q-98-35-175-83-127-79-264-216t-216-264q-48-77-83-175-3-9-18-49t-20.5-55.5-14.5-47-12.5-57.5-3.5-53q0-92 51-186 56-101 106-122 25-11 68.5-21t70.5-10q14 0 21 3 18 6 53 76 11 19 30 54t35 63.5 31 53.5q3 4 17.5 25t21.5 35.5 7 28.5q0 20-28.5 50t-62 55-62 53-28.5 46q0 9 5 22.5t8.5 20.5 14 24 11.5 19q76 137 174 235t235 174q2 1 19 11.5t24 14 20.5 8.5 22.5 5q18 0 46-28.5t53-62 55-62 50-28.5q14 0 28.5 7t35.5 21.5 25 17.5q25 15 53.5 31t63.5 35 54 30q70 35 76 53 3 7 3 21z">
              </path>
            </svg></span>
          <a href="#">
            <p class="mbr-fonts-style phone mbr-semibold mbr-white display-6">080 4166 0033</p>
          </a>
        </div>
        <div class="mbr-col-sm-12 mbr-flex mbr-jc-e bottom__right mbr-col-md-6">
          <!-- SOCIAL ICON -->
          <div class="menu-social-list">
            <a href="http://www.youtube.com/channel/UCt_tncVAetpK5JeM8L-8jyw" target="_blank">
              <span class="amp-iconfont fa-youtube-play fa"><svg width="15" height="15" viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                  <path
                    d="M711 1128l484-250-484-253v503zm185-862q168 0 324.5 4.5t229.5 9.5l73 4q1 0 17 1.5t23 3 23.5 4.5 28.5 8 28 13 31 19.5 29 26.5q6 6 15.5 18.5t29 58.5 26.5 101q8 64 12.5 136.5t5.5 113.5v176q1 145-18 290-7 55-25 99.5t-32 61.5l-14 17q-14 15-29 26.5t-31 19-28 12.5-28.5 8-24 4.5-23 3-16.5 1.5q-251 19-627 19-207-2-359.5-6.5t-200.5-7.5l-49-4-36-4q-36-5-54.5-10t-51-21-56.5-41q-6-6-15.5-18.5t-29-58.5-26.5-101q-8-64-12.5-136.5t-5.5-113.5v-176q-1-145 18-290 7-55 25-99.5t32-61.5l14-17q14-15 29-26.5t31-19.5 28-13 28.5-8 23.5-4.5 23-3 17-1.5q251-18 627-18z">
                  </path>
                </svg></span>
            </a><a href="https://www.facebook.com/pages/Mobirise/1616226671953247" target="_blank">
              <span class="amp-iconfont fa-facebook-f fa"><svg width="15" height="15" viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                  <path
                    d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z">
                  </path>
                </svg></span>
            </a><a href="https://www.facebook.com/pages/Mobirise/1616226671953247" target="_blank">
              <span class="amp-iconfont fa-linkedin fa"><svg width="15" height="15" viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                  <path
                    d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z">
                  </path>
                </svg></span>
            </a><a href="https://www.facebook.com/pages/Mobirise/1616226671953247" target="_blank">
              <span class="amp-iconfont fa-vimeo-square fa"><svg width="15" height="15" viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                  <path
                    d="M1420 638q10-216-161-222-231-8-312 261 44-19 82-19 85 0 74 96-4 57-74 167t-105 110q-43 0-82-169-13-54-45-255-30-189-160-177-59 7-164 100l-81 72-81 72 52 67q76-52 87-52 57 0 107 179 15 55 45 164.5t45 164.5q68 179 164 179 157 0 383-294 220-283 226-444zm244-222v960q0 119-84.5 203.5t-203.5 84.5h-960q-119 0-203.5-84.5t-84.5-203.5v-960q0-119 84.5-203.5t203.5-84.5h960q119 0 203.5 84.5t84.5 203.5z">
                  </path>
                </svg></span>
            </a></div>
          <!-- SOCIAL ICON END -->
        </div>
      </div>
      <div class="divider__foot"></div>
      <div class="footer-container container">
        <div class="copyright align-left">
          <p class="mbr-text mbr-fonts-style mbr-white display-4">© Copyright 2021 Chira Information
            Technology - All Rights
            Reserved</p>
        </div>
      </div>
    </div>
  </section>

</div>
